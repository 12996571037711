import { Injectable, OnInit } from '@angular/core';

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  catchError,
  retry,
  timeout,
  map,
  filter,
  retryWhen,
  switchMap,
  concatMap,
  mergeMap,
  tap,
  finalize,
  take,
  delay,
} from 'rxjs/operators';
import { EMPTY, Observable, pipe, Subject, throwError } from 'rxjs';
import { AlertController, LoadingController } from '@ionic/angular';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { LoaderService } from './loader.service';
import * as moment from 'moment'


const URL = environment.url;
const URLPDF =  environment.urlpdf;
const URLEXCEL =  environment.urlexcel;

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  public nTimeout: number = 45000;
  public nRetry: number = 5;
  loading;
  moment:any = moment;

  constructor(
    private http: HttpClient,
    public loadingController: LoadingController,
    private alertController: AlertController,
    private loaderService: LoaderService //private loadingService:LoaderService
  ) {}

  private handleError(error: HttpErrorResponse) {
    
    this.sendClickToErrorHttp();

    this.presentAlert('Ocurrio un error en el servidor. '+error);
    return throwError('Algo ocurrio por favor trate nuevamente.');
  }


  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    //Authorization: this.authService.getToken()
  });

  setTokenFCM(newUser: any) {
    return this.http
      .post(`${URL}/setTokenFcm`, newUser, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }
  uploadFoto(archivo) {
    console.log('url: ', URL);

    console.log('Subir esta data: ', archivo);

    return this.http.post(`${URL}`, archivo, { headers: this.headers });
  }

  PetitionTests({}) {
    return this.http.post(`${URL}/getTextTest4`, {}, { headers: this.headers });
  }

  PetitionTestsParams(variable) {
    return this.http.post(
      `${URL}/getAuthUser?vte=${variable}`,
      {},
      { headers: this.headers }
    );
  }

  setUser_getData(newUser: any) {
    return this.http
      .post(`${URL}/setUser_getData`, newUser, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  setCompany_getData(newDataStore: any) {
    return this.http
      .post(`${URL}/setCompany_getData`, newDataStore, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updCompanySettings(newDataStore: any) {
    return this.http
      .post(`${URL}/updCompanySettings`, newDataStore, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  setCompanyUser_getData(newCompanyUser: any) {
    return this.http
      .post(`${URL}/setCompanyUser_getData`, newCompanyUser, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getCompanyUserByUserId(request) {
    console.log('esto mandamos a la base de datos: ', request);
    //this.presentLoading('Cargando datos...')
    const url = `${URL}/getCompanyUserByUserId`;
    console.log('esta es la url: ', url);

    return this.http.post(url, request, { headers: this.headers }).pipe(
      tap(() => {}),

      retry(this.nRetry),
      timeout(this.nTimeout),
      catchError((err) => {
        this.handleError(err);
        return err;
      }),
      tap(() => {}),
      finalize(() => {
        // this.loading.dismiss();
      })
    );

    /* return  this.http.get(url).pipe(
      
      retry(this.nRetry), // retry a failed request up to 3 times
      timeout(this.nTimeout),
       catchError((err) => {
        this.handleError(err);
        return err;
      }), // then handle the error
    ); */
  }

  getCompanyById(idCompanyRequesty: any) {
    return this.http
      .post(`${URL}/getCompanyById`, idCompanyRequesty, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updCompany_getData(updCompany) {
    return this.http
      .post(`${URL}/updCompany_getData`, updCompany, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  //---nueva categoría--//
  setCategory_getData(newCategory: any) {
    return this.http
      .post(`${URL}/setCategory_getData`, newCategory, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getCategories(data: any) {
    ////this.presentLoading('Cargando categorias...')
    return this.http.post(`${URL}/getCategories`, data , { headers: this.headers } ).pipe(
      tap((data: any) => {}),

      retry(this.nRetry), // retry a failed request up to 3 times
      timeout(this.nTimeout),
      catchError((err) => {
        this.handleError(err);
        return err;
      }),
      tap(() => {}), // then handle the error,
      finalize(() => {
        this.loadingController.getTop().then((load) => {
          if (load) {
            this.loadingController.dismiss();
          }
        });
      })
    );
  }

  updCategory(updCategory) {
    return this.http
      .post(`${URL}/updCategory`, updCategory, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  setNewproduct(newCategory: any) {

    return this.http
      .post(`${URL}/setNewproduct_getData`, newCategory, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
      
  }

  setNewStockProduct(newStockProduct: any) {
    console.log('esto estamos enviando al back: ', newStockProduct);

    return this.http
      .post(`${URL}/setNewStockProduct_getData`, newStockProduct, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  //retornar lista de priductos:
  getAllproducts(data: any) {
    console.log('getAll prducts: ', data);
    ////this.presentLoading('Obteniendo productos...');
    // this.presentLoading('Cargando...');
    return this.http.post(`${URL}/getAllProducts`, data, { headers: this.headers } ).pipe(
      map((prod: any) =>
       prod.data
      
      
      ),
      tap((pick) => {
        let counter = pick.length == 0 ? 0 : pick.length;
        //console.log( 'la cantidad: ', pick.length);
        localStorage.setItem(environment.numberprods, counter);
      }),

      retry(this.nRetry), // retry a failed request up to 3 times
      timeout(this.nTimeout),
      catchError((err) => {
        this.handleError(err);
        return err;
      }),
      tap(() => {}), // then handle the error
      finalize(() => {
        // this.loading.dismiss();
      })
    );
  }

  //----------------------------------Edicion de un producto---------------------------------//
  getProductWithStock(data: any) {
    //this.presentLoading('Cargando datos...');

    return this.http
      .post(`${URL}/getProductWithStock`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry), // retry a failed request up to 3 times
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }), // then handle the error
        finalize(() => {
          //this.loading.dismiss();
        })
      );
  }

  //Actualizar tabla de prioductos:
  updProduct_getData( updProduct) {
    return this.http
      .post(`${URL}/updProduct_getData`, updProduct, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  //-------en duda----//
  updSmallProduct_getData( updProduct) {
    return this.http
      .post(`${URL}/updSmallProduct_getData`, updProduct, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  //
  updStock_getData( updStock) {
    return this.http
      .post(`${URL}/updStockShopping_getData`, updStock, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  

  getProductsWithMinStock(data: any) {
    //this.presentLoading('Cargando productos con stock mínimo...');
    return this.http
      .post(`${URL}/getProductsWithMinStock`, data, { headers: this.headers })
      .pipe(
        tap(() => {}),
        timeout(this.nTimeout),
        retry(this.nRetry),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          //this.loading.dismiss();
        })
      );
  }

  setNewCustomer(data: any) {
    return this.http
      .post(`${URL}/setNewCustomer_getData`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updCustomer_getData(data: any) {
    return this.http
      .post(`${URL}/updCustomer_getData`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  //getCustomersByCompanyID
  getCustomersByCompanyID(data: any) {
    //this.presentLoading('Cargando clientes...')
    return this.http
      .post(`${URL}/getCustomersByCompanyID`, data, { headers: this.headers })
      .pipe(
        tap(() => {}),

        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          // this.loading.dismiss();
        })
      );
  }

  setHeaderSale(data: any) {
    return this.http
      .post(`${URL}/setHeaderSale_getData`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  setSaleTable(data: any) {
    return this.http
      .post(`${URL}/setSalesTable_getData`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getHeaderSales(data: any) {
    console.log('VEntas del dia');
    //this.presentAlert("");

    //this.presentLoading('Cargando...')

    return this.http
      .post(`${URL}/getHeaderSales`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          this.loading.dismiss();
        })
      );
  }

  getSalesTable(data: any) {
    console.log('Inicia ventas!!');

    //this.presentLoading('Cargando ventas...');
    return this.http
      .post(`${URL}/getSalesTable`, data, { headers: this.headers })
      .pipe(
        tap(() => {}),

        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          console.log('FINALIZAAA!!!');

          //this.loading.dismiss();
        })
      );
  }

  validateStock(data: any) {
    return this.http
      .post(`${URL}/validateStock`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updSale(data: any) {
    return this.http
      .post(`${URL}/updSale`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  filterHeader(data: any) {
    return this.http
      .post(`${URL}/filterHeader`, data, { headers: this.headers })
      .pipe(
        tap(() => {}),

        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {})
      );
  }

  getHeaderSale(data: any, flag?) {
    // flag&&flag==1?this.presentLoading('Cargando datos...'):null;

    return this.http
      .post(`${URL}/getHeaderSale`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          //this.loading.dismiss();
        })
      );
  }

  getCustomersByID(data: any) {
    return this.http
      .post(`${URL}/getCustomersByID`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updHeaderSale(data: any) {
    //this.presentLoading('Actualizando...')
    return this.http
      .post(`${URL}/updHeaderSale`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          // this.loading.dismiss();
        })
      );
  }

  getSalesAndDiscuonts(data: any) {
    // this.presentLoading('Cargando datos...')
    return this.http
      .post(`${URL}/getSalesAndDiscuonts`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          // this.loading.dismiss();
        })
      );
  }

  updHeaderSaleAddStock(data: any) {
    return this.http
      .post(`${URL}/updHeaderSaleAddStock`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updCatalogStock(data: any) {
    // this.presentLoading('Actualizando...')
    return this.http
      .post(`${URL}/updCatalogStock_getData`, data, { headers: this.headers })
      .pipe(
        map((products: any) =>
        
          products.data.map((item) => ({
            ...item,
            C_CATALOG: item.C_CATALOG == '1' ? true : false,
          }))
        ),
        tap((rpta) => {
          console.log('Vamoa aver(upd): ', rpta);
        }),

        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          //  this.loading.dismiss();
        })
      );
  }
  addImageToProduct(data: any) {
    return this.http
      .post(`${URL}/addImageToProduct`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updResources(data: any) {
    return this.http
      .post(`${URL}/updResources`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  setDeliverysConf(data: any) {
    return this.http
      .post(`${URL}/setDeliveryConf_getData`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updDeliverysConf(data: any) {
    return this.http
      .post(`${URL}/updDeliveryConf_getData`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getDeliverysConf(data: any) {
    return this.http
      .post(`${URL}/getDeliveryConf_getData`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getPayMethods(data: any) {
    // this.presentLoading('Cargando datos...')
    return this.http
      .post(`${URL}/getPayMethods`, data, { headers: this.headers })
      .pipe(
        tap(() => {}),

        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          //  this.loading.dismiss();
        })
      );
  }

  updatePayMethods(data: any) {
    return this.http
      .post(`${URL}/updatePayMethods`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getParameters(data: any) {
    return this.http
      .post(`${URL}/getParameters`, data, { headers: this.headers })
      .pipe(
        map((params: any) =>
          params.data.map((param) => ({
            ...param,
            typeSale: '1',
          }))
        ),

        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  setParameter(data: any) {
    return this.http
      .post(`${URL}/setParameter`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updParameters(data: any) {
    return this.http
      .post(`${URL}/updParameters`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updParameter(data: any) {
    return this.http
      .post(`${URL}/updParameter`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getConPedidos(data: any) {
    return this.http
      .post(`${URL}/getConPedidos`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  setConPedidos(data: any) {
    this.presentLoading('Obteniendo configuración...');
    return this.http
      .post(`${URL}/setConPedidos`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        finalize(() => {
          this.loading.dismiss();
        })
      );
  }

  updConPedidos(data: any) {
    return this.http
      .post(`${URL}/updConPedidos`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getAllHeaderSales(data: any) {
    // this.presentLoading("Cargando pedidos...");
    return this.http
      .post(`${URL}/getAllHeaderSales`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          // this.loading.dismiss();
        })
      );
  }

  setSubPayment(data: any) {
    return this.http
      .post(`${URL}/setSubPayment_getData`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updPayMethod(data: any) {
    return this.http
      .post(`${URL}/updPayMethod`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getPayMethodByParameterCodPar(data: any) {
    return this.http
      .post(`${URL}/getPayMethodByParameterCodPar`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  setPayMethodMobileConf(data: any) {
    return this.http
      .post(`${URL}/setPayMethodMobileConf`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getPayMethodMobileConf(data: any) {
    //this.presentLoading('Cargando cuentas...')

    return this.http
      .post(`${URL}/getPayMethodMobileConf`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          // this.loading.dismiss();
        })
      );
  }

  getIdProductByCodbar(data: any) {
    return this.http
      .post(`${URL}/getIdProductByCodbar`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getSaleToDevolutionCodBar(data: any) {
    return this.http
      .post(`${URL}/getSaleToDevolutionCodBar`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  validateMail(data: any) {
    return this.http
      .post(`${URL}/validateMail`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updateUserData(data: any) {
    return this.http
      .post(`${URL}/updateUserData`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getUserById(data: any) {
    return this.http
      .post(`${URL}/getUserById`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  reestablishPasssword(data: any) {
    //this.presentLoading('Enviando solicitud...')
    return this.http
      .post(`${URL}/reestablishPasssword`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          //this.loading.dismiss();
        })
      );
  }

  sendReceiptToCustomerMail(data: any) {
    // this.presentLoading('Enviando solicitud...')
    return this.http
      .post(`${URL}/sendReceiptToCustomerMail`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          // this.loading.dismiss();
        })
      );
  }

  getStocksAndHisProducts(data: any) {
    // this.presentLoading('Enviando solicitud...')
    return this.http
      .post(`${URL}/getStocksAndHisProducts`, data, { headers: this.headers })
      .pipe(
        timeout(this.nTimeout),
        map((products: any) =>
          products.data.map((item) => ({
            ...item,
            C_CATALOG: item.C_CATALOG == '1' ? true : false,
          }))
        ),
        tap((rpta) => {
          console.log('Vamoa aver: ', rpta);
        }),

        retry(this.nRetry),
        catchError((err) => {
          this.handleError(err);
          return err;
        }),
        tap(() => {}),
        finalize(() => {
          // this.loading.dismiss();
        })
      );
  }

  getCreditNote(data: any) {
    return this.http
      .post(`${URL}/getCreditNote`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updHeaderSaleAndCompany(data: any) {
    return this.http
      .post(`${URL}/updHeaderSaleAndCompany`, data, { headers: this.headers })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  //--------------product------------------------//
  private productsCompo = new Subject<any>();
  sendClickToProductsCompo() {
    this.productsCompo.next();
  }

  getClickToProductsCompo(): Observable<any> {
    return this.productsCompo.asObservable();
  }
  //--------------- product register -----------------------//

  private productRegister = new Subject<any>();
  sendClickToProductRegister(data?) {
    this.productRegister.next(data);
  }

  getClickToProductRegister(): Observable<any> {
    return this.productRegister.asObservable();
  }
//---------------------------------------------------------//
  //--------------- product register -----------------------//

  private registerOption = new Subject<any>();
  sendClickToRegisterOption(data?) {
    this.registerOption.next(data);
  }

  getClickToRegisterOption(): Observable<any> {
    return this.registerOption.asObservable();
  }
//---------------------------------------------------------//

  private subjectt = new Subject<any>();
  sendClickEvent() {
    this.subjectt.next();
  }

  getClickEvent(): Observable<any> {
    return this.subjectt.asObservable();
  }
  //-------------------------------------//
  private subjectCategory = new Subject<any>();
  sendCategoryEvent() {
    this.subjectCategory.next();
  }

  getCategoryEvent(): Observable<any> {
    return this.subjectCategory.asObservable();
  }

  //--------------------------------------//
  private cartSubect = new Subject<any>();
  sendCartEvent(data?: string) {
    this.cartSubect.next(data);
  }

  getCartEvent(): Observable<any> {
    return this.cartSubect.asObservable();
  }

  //--------------category------------------------//
  private reloadCategory = new Subject<any>();
  sendReloadCategory() {
    this.reloadCategory.next();
  }

  getReloadCategory(): Observable<any> {
    return this.reloadCategory.asObservable();
  }

  getAccountsByIdTypePayment(data: any) {
    return this.http
      .post(`${URL}/getAccountsByIdTypePayment`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  deleteResources(data: any) {
    return this.http
      .post(`${URL}/deleteResources`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  //-------------------------------------//

  async presentLoading(message: string) {
    this.loading = await this.loadingController.create({
      message,
      //duration: 2000,
    });
    return this.loading.present();
  }

  async presentAlert(msm: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Oops!',
      //subHeader: "Ocurrio un problema con el servidor.",
      message: msm,

      buttons: ['OK'],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  //-------------- setTime ------------------------//
  private ErrorHttp = new Subject<any>();
  sendClickToErrorHttp() {
    this.ErrorHttp.next();
  }

  getClickToErrorHttp(): Observable<any> {
    return this.ErrorHttp.asObservable();
  }
  //--------------------------------------//

  insGoalst(data: any) {
    return this.http
      .post(`${URL}/insGoals`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updGoals(data: any) {
    return this.http
      .post(`${URL}/updGoals`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getGoals(data: any) {
    console.log('url: ', `${URL}/getGoals`);
    return this.http
      .post(`${URL}/getGoals`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  delGoals(data: any) {
    return this.http
      .post(`${URL}/delGoals`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  deleteHeaderSaleById(data: any) {
    return this.http
      .post(`${URL}/deleteHeaderSaleById`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  //-------------- setRegisterProductScanner ------------------------//
  private scanBarcode = new Subject<any>();
  sendClickToScanBarcode(data) {
    this.scanBarcode.next(data);
  }

  getClickToScanBarcode(): Observable<any> {
    return this.scanBarcode.asObservable();
  }
  //--------------------------------------//
  //-------------- setRegisterProductScanner ------------------------//
  private optionAccordion = new Subject<any>();
  sendClickOptionAccordion() {
    this.optionAccordion.next();
  }

  getClickOptionAccordion(): Observable<any> {
    return this.optionAccordion.asObservable();
  }
  //--------------------------------------//

  getExpences(data: any) {
    return this.http
      .post(`${URL}/getExpences`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  insExpences(data: any) {
    return this.http
      .post(`${URL}/insExpences`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updExpences(data: any) {
    return this.http
      .post(`${URL}/updExpences`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  //-------------- setRegisterProductScanner ------------------------//
  private listOfOrders = new Subject<any>();
  sendClickListOfOrders(data) {
    this.listOfOrders.next(data);
  }

  getClickListOfOrders(): Observable<any> {
    return this.listOfOrders.asObservable();
  }
  //--------------------------------------//

  getValuesGoals(data: any) {
    
    return this.http
      .post(
        `${URL}/getValuesGoals
`,
        data,
        {
          headers: this.headers,
        }
      )
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getVersionApp() {
    
    return this.http
      .post(
        `${URL}/GetVersionApp
` 
        , {}
       
      )
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }
  //android\app\build.gradle




//getGraddle
getVersionAndroid(){

  return this.http.get('./appp/build.gradle', { responseType: 'text' as 'json' })
    
  
}


//ios\App\App\Info.plist

getVersionIos(){

  return this.http.get('./ioss/Info.plist', { responseType: 'text'  })
    
  
}

  
  
  deleteProductById(data: any) {
    return this.http
      .post(`${URL}/delProductAndStock_getData`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getProductsByCategoryId(data: any) {
    return this.http
      .post(`${URL}/getProductsByCategoryId`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

//----------------------------------------------------------------------//
public diario:Array<any> = [
  {
    'periodo':'3',
    'ventasTotales':'4500',
    'costosTotales':'9900',
    'gastosTotales':'11210',
    'utility':'334500',
    'goal':'500'
  }
  ]


  public semanal:Array<any> = [
    {
      'periodo':'1',
      'ventasTotales':'6565',
      'costosTotales':'3213',
      'gastosTotales':'180',
      'utility':'113699',
      'goal':'25500'
    }
  ]


  public mensual:Array<any> = [
    {
      'periodo':'2',
      'ventasTotales':'6565',
      'costosTotales':'89851',
      'gastosTotales':'1800',
      'utility':'20000',
      'goal':'1100'
    }
  ]



  getBalanceByPeriod(data: any) {
    return this.http
      .post(`${URL}/getBalanceByPeriod`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getStockTable(data: any) {
    return this.http
      .post(`${URL}/getStockTable`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  updStockAndCreateExpense(data: any) {
    return this.http
      .post(`${URL}/updStockAndCreateExpense`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  setBoxInfo(data: any) {
    return this.http
      .post(`${URL}/setBoxInfo`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getBoxInfoByPeriod(data: any) {
    return this.http
      .post(`${URL}/getBoxInfoByPeriod`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getExpencesPagination(data: any) {
    return this.http
      .post(`${URL}/getExpencesPagination`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getExpencesByPeriod(data: any) {
    return this.http
      .post(`${URL}/getExpencesByPeriod`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }
  

  getSalesByProduct(data: any) {
    return this.http
      .post(`${URL}/getSalesByProduct_filterdate`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }



  getUtilByProduct(data: any) {
    return this.http
      .post(`${URL}/getUtilByProduct_filterdate`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  
  getAmountCommodity(data: any) {
    return this.http
      .post(`${URL}/getAmountCommodity`, data, {
        headers: this.headers,
      })
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }
  //-----------------------------------------------------------------------//

  getMenu(data: any) {
    return this.http
      .post(`${URL}/getMenuChat`, data)
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }


  getChatsByDate(data: any) {
    return this.http
      .post(`${URL}/getChatsByDate`, data)
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getChatInfo(data: any) {
    return this.http
      .post(`${URL}/getChatInfo`, data)
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  setMessageChat(data: any) {
    return this.http
      .post(`${URL}/setMessageChat`, data)
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  editChat(data: any) {
    return this.http
      .post(`${URL}/editChat`, data)
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

  getResponse(data: any) {
    return this.http
      .post(`${URL}/getResponse`, data)
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }



  associateAccount(data: any) {
    return this.http
      .post(`${URL}/associateAccount`, data)
      .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }

//-------------------//

  //--------------- product register -----------------------//

  private getResponseFromBoot = new Subject<any>();
  sendClickTogetResponseFromBoot(data?) {
    this.getResponseFromBoot.next(data);
  }

  getClickTogetResponseFromBoot(): Observable<any> {
    return this.getResponseFromBoot.asObservable();
  }


  searchProductByName(data: any) {
    return this.http
      .post(`${URL}/searchProductByName`, data)
      .pipe(
        
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  }





  getReportByDate( data ):Promise<string>{

    return new Promise( (resolve , reject)=>{
      let src = '';
        src =  `${URLPDF}/getReportProduct_filterdate?IDCOMPANY=${data.idcompany}&start=${data.start}&end=${data.end}`;
        resolve(src);
    } );
  }

  dowloadReportByDate( data ):Promise<string>{

    return new Promise( (resolve , reject)=>{
      let src = '';
      src = `${URLPDF}/getReportProduct_vt_filterdate?share=1&token=${data.token}`
      resolve(src);
    } );    
  }


  sharePdf(data){

   return this.http.get( `${URLPDF}/getReportProductv2_filterdate?IDCOMPANY=${data.idcompany}&start=${data.start}&end=${data.end}&share=1` ) .pipe(
      retry(this.nRetry),
      timeout(this.nTimeout),
      catchError((err) => {
        this.handleError(err);
        return err;
      })
    );

  }
  sharePdfHtml(data){

    return this.http.get( `${URLPDF}/getReportProductv2Hmtl_filterdate?IDCOMPANY=${data.idcompany}&start=${data.start}&end=${data.end}&share=1` ) .pipe(
       retry(this.nRetry),
       timeout(this.nTimeout),
       catchError((err) => {
         this.handleError(err);
         return err;
       })
     );
 
   }
  

  getUrlSharePdf(data){
    
    return  `${URLPDF}/getReportProduct_vt_filterdate?share=1&token=${data.token}`;
 
   }
    getUrlCommodityExcel(data){
    return  `${URLEXCEL}/getReportShoppingExcel_filterdate/${data.token}`;
 
   }

   
   getUrlResumenExcel(data){
    return  `${URLEXCEL}/getReportProductv2Excel_filterdate/${data.token}`;
 
   }
   getUrlInventoryExcel(data){
    return  `${URLEXCEL}/getAmountCommodityExcelTotal/${data.token}`;
 
   }

   getUrlOutProductExcel(data){
    return  `${URLEXCEL}/getReportProductOutExcel_filterdate/${data.token}`;
 
   }
   

   getVarsUrlSharePdf(data){
    return  `${URLPDF}/getReportProduct_vt_filterdate?IDCOMPANY=${data.idcompany}&start=${data.start}&end=${data.end}&share=1`;
 
   }

  getReportShoppingByDate( data ){

    return this.http.get( `${URLPDF}/getReportShoppingHtml_filterdate?IDCOMPANY=${data.idcompany}&start=${data.start}&end=${data.end}&all=${data.all}` ) .pipe(
      retry(this.nRetry),
      timeout(this.nTimeout),
      catchError((err) => {
        this.handleError(err);
        return err;
      })
    );
  }

  getReportCutInventoriesSeries_filterdate( data ){

    return this.http.get( `${URLPDF}/getReportCutInventoriesSeries_filterdate?IDCOMPANY=${data.idcompany}&start=${data.start}&end=${data.end}&all=${data.all}` ) .pipe(
      retry(this.nRetry),
      timeout(this.nTimeout),
      catchError((err) => {
        this.handleError(err);
        return err;
      })
    );
  }

    getReportProductOutHmtlByDate( data ){

      return this.http.get( `${URLPDF}/getReportProductOutHmtl_filterdate?IDCOMPANY=${data.idcompany}&start=${data.start}&end=${data.end}&all=${data.all}` ) .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );

    
    

  }
  getAmountCommodityTotal( data ){

    /* return new Promise( (resolve , reject)=>{
       let src = '';

        src =  `${URLPDF}/getAmountCommodityTotal?IDCOMPANY=${data.idcompany}&start=${data.start}&end=${data.end}&all=${data.all}`;

         resolve(src);
    } ) ; */

    return this.http.get( `${URLPDF}/getAmountCommodityHtmlTotal?IDCOMPANY=${data.idcompany}&start=${data.start}&end=${data.end}&all=${data.all}&searchtext=${data.searchtext}` ) .pipe(
      retry(this.nRetry),
      timeout(this.nTimeout),
      catchError((err) => {
        this.handleError(err);
        return err;
      })
    );

    

  }

    //-------------- setRegisterProductScanner ------------------------//
    private modifyStock = new Subject<any>();
    sendClickmodifyStock() {
      this.modifyStock.next();
    }
  
    getClickmodifyStock(): Observable<any> {
      return this.modifyStock.asObservable();
    }
    //--------------------------------------//
  
    get_detmenu(data: any) {
      return this.http
        .post(`${URL}/get_detmenu`, data)
        .pipe(
          
          retry(this.nRetry),
          timeout(this.nTimeout),
          catchError((err) => {
            this.handleError(err);
            return err;
          })
        );
    }
  


    get_infomenu( ) {
      return this.http
        .post(`${URL}/get_infomenu`,{})
        .pipe(
          
          retry(this.nRetry),
          timeout(this.nTimeout),
          catchError((err) => {
            this.handleError(err);
            return err;
          })
  
  
  
        );
    }
  

    shareCommodityExcel( data ){

      return this.http.get( `${URLPDF}/getReportShoppingExcelv2_filterdate?IDCOMPANY=${data.idcompany}&start=${data.start}&end=${data.end}&all=${data.all}` ) .pipe(
        retry(this.nRetry),
        timeout(this.nTimeout),
        catchError((err) => {
          this.handleError(err);
          return err;
        })
      );
  

    }


    downloadComodityReport( data ){

      return new Promise( (resolve , reject)=>{
         let src = '';
  
          src =  `${URLPDF}/getReportShoppingExcelv2_filterdate?IDCOMPANY=${data.idcompany}&start=${data.start}&end=${data.end}&all=${data.all}`;
  
           resolve(src);
      } ) ;
    }


}
   