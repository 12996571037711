import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class MenutoggleService {
    menu = new BehaviorSubject('close');

    constructor() {

    }

    setMenu(x: any) {
        this.menu.next(x);
    }

    getMenu(): Observable<any> {
        return this.menu.asObservable();
    }

}
