
import { hrtime } from "process";
import { RESOURCE_BY_ROLES } from "./internalRoutes";
import {  ROLES_ENUM } from "./roles.enum";
import { IMenu } from "./ui-menu.model";



export const Menu_titles:IMenu[] = [
    {
        title:"Resumen",
        icon:"clipboard",
        href:"/main-page",
        role: RESOURCE_BY_ROLES.RESUMEN_OPTION
    }
    ,

{
    title:"Reportes",
    icon:"clipboard",
    href:"reports",
    role:RESOURCE_BY_ROLES.REPORT_OPTION,
    imagen:"Reportes.svg",
    
    
},

    {
        title:"Vender y pedidos",
        icon:"newspaper",
        href:"/pending-finished",
        role: RESOURCE_BY_ROLES.VENDER_OPTION,
        imagen:"Vender.svg",
        
    },

    

    {
        title:"Mis gastos/retiros",
        icon:"receipt",
        href:"/my-expenses",
        role:RESOURCE_BY_ROLES.EXPENSES_OPTION
        
    },
    
    {
        title:"Movimiento dinero",
        icon:"cash",
        href:"/box-money",
        role:RESOURCE_BY_ROLES.MOVES_OPTION,
        imagen:"Movimientodedinero.svg",
        
        
    },
    {
        title:"Mis clientes",
        icon:"people",
        href:"/my-clients",
        role: RESOURCE_BY_ROLES.CLIENTS_OPTION,
        imagen:"clientes3.svg",

    },
    {
        title:"Mis productos",
        href:"/products",
        icon:"shirt",
        role:RESOURCE_BY_ROLES.PRODUCTS_OPTION,
        imagen:"products.svg",
        
    },
    {
        title:"Mi catálogo web",
        icon:"book",
        href:"/catalog",
        role:RESOURCE_BY_ROLES.CATALOG_OPTION,
        imagen:"catalogo1.svg",
        
    },
    /*
    {
        title:"Utilidad",
        href:"/balance",
        icon:"stats-chart",
        role:RESOURCE_BY_ROLES.BALANCE_OPTION
        
    }
    ,*/
    {
        title:"Información",
        href:"/inform-yourself",
        icon:"information",
        role:RESOURCE_BY_ROLES.INFORMATION_OPTION,
        imagen:"informacion.svg",
        
    },
    {
        title:"Configuraciones",
        icon:"cog",
        href:"/settings",
        role:RESOURCE_BY_ROLES.SETTINGS_OPTION,
        imagen:"configuraciones.svg",
    }
,
{
    title:"Cambiar contraseña",
    icon:"person",
    href:"/client",
    imagen:"Cambiarcontraseña.svg",
    role:RESOURCE_BY_ROLES.CHANGE_PASSWORD
    
}
,{
    title:"Asesor digital",
    icon:"logo-wechat",
    href:"/chat",
    role:RESOURCE_BY_ROLES.ADVISER_OPTION
    
},
/*
{
    title:"Alerta stock",
    icon:"alert",
    href:"/min-stock-modal",
    role:RESOURCE_BY_ROLES.ALERT_OPTION
    
},
*/
/*
{
    title:"Asociar cuenta",
    icon:"people",
    href:"/associate-accounts",
    role:RESOURCE_BY_ROLES.ADD_USER
    
},*/

{
    title:"Cerrar Sesión",
    icon:"exit",
    href:"close",
    role:RESOURCE_BY_ROLES.CLOSE_SESSION,
    imagen:"Cerrarsesion.svg",
    
    
}

   /*  {
        title:"Vender",
        icon:"checkmark-circle-outline",
        href:"/sell",
        role: RESOURCE_BY_ROLES.VENDER_OPTION
    },


    {
        title:"Mis pedidos",
        icon:"cart",    
        href:"/orders",
        role:RESOURCE_BY_ROLES.ORDERS_OPTION

    },
    */
   



    //--------------------------------------------------//



   /*  {
        title:"Objetivos",
        icon:"flash",
        href:"/objectives",
        role:RESOURCE_BY_ROLES.CATALOG_OPTION
        
    }, */
   
   


/*   {
        title:"Registar Productos",
        icon:"clipboard",
        href:"/product-register",
        role: RESOURCE_BY_ROLES.PRODUCT_REGISTER_OPTION
        
    }, */

   /*  {
        title:"Transacciones",
        icon:"cash",
       
    }, */
   /*  {
        title:"Estadísticas",
        icon:"podium"
    }, */
    /* {
        title:"Usuarios",
        icon:"person",
        href:"/user-register"
    }, */
   
]