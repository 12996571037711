import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {

  transform(arreglo: any[] , text: any)  {

    console.log("Sale pipe arr: ", arreglo );
    console.log("Sale pipe text: ", text );

    if(text === ''){
      return arreglo;
    }

    text = text.toLowerCase();

    return arreglo.filter( item =>{
      return item.C_DESCAT.toLowerCase().includes( text );
    })


  }

}
