import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { FilterHeaderPipe } from './filter-header.pipe';
import { FilterSalePipe } from './filter-sale.pipe';
import { ClientsFilterPipe } from './clients.pipe';
import { DatePipe } from './date.pipe';
import { OrdersPipe } from './orders.pipe';
import { CurrencyPipe } from './currency.pipe';
import { ClientPipe } from './client.pipe';
import { PayMethodsPipe } from './pay-methods.pipe';
import { MyExpencesPipe } from './my-expences.pipe';
import { ReversePipe } from './reverse.pipe';
import { CategoryPipe } from './category.pipe';
import { DescriptionExpensePipe } from './description-expense.pipe';
import { OrderSpecificPipe } from './order-specific.pipe'

@NgModule({
  declarations: [ FilterPipe, FilterHeaderPipe, FilterSalePipe, ClientsFilterPipe, DatePipe, OrdersPipe, CurrencyPipe, ClientPipe, PayMethodsPipe, MyExpencesPipe, ReversePipe, CategoryPipe, DescriptionExpensePipe, OrderSpecificPipe ],
  exports:[ FilterPipe,FilterHeaderPipe,FilterSalePipe,ClientsFilterPipe ,DatePipe,OrdersPipe , CurrencyPipe , ClientPipe , PayMethodsPipe, MyExpencesPipe,ReversePipe,  CategoryPipe , DescriptionExpensePipe, OrderSpecificPipe]

})
export class PipesModule { }
