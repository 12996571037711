import { ROLES_ENUM } from "./roles.enum";




export const RESOURCE_BY_ROLES = {
    
    
    /*Resumen*/RESUMEN_OPTION:[ROLES_ENUM.TEST],
    /*Reportes*/REPORT_OPTION:[ROLES_ENUM.TEST,ROLES_ENUM.PREMIUM],
    /*Mis gastos/retiros*/EXPENSES_OPTION: [ROLES_ENUM.TEST],
    /*Movimiento dinero*/ MOVES_OPTION:[ROLES_ENUM.NORMAL , ROLES_ENUM.PREMIUM,ROLES_ENUM.TEST,ROLES_ENUM.MANAGER],
    /*Mis clientes*/CLIENTS_OPTION:[ROLES_ENUM.NORMAL , ROLES_ENUM.PREMIUM,ROLES_ENUM.TEST,ROLES_ENUM.MANAGER,ROLES_ENUM.SALES],
    /*Mis productos*/PRODUCTS_OPTION : [ROLES_ENUM.NORMAL , ROLES_ENUM.PREMIUM,ROLES_ENUM.TEST,ROLES_ENUM.MANAGER],
    /*Mi catálogo web*/CATALOG_OPTION:  [ROLES_ENUM.NORMAL, ROLES_ENUM.PREMIUM,ROLES_ENUM.TEST,ROLES_ENUM.MANAGER,ROLES_ENUM.SALES],
    /*Utilidad*/ BALANCE_OPTION: [ROLES_ENUM.TEST,ROLES_ENUM.NORMAL,ROLES_ENUM.PREMIUM],
    /*Configuraciones*/SETTINGS_OPTION:  [ROLES_ENUM.NORMAL , ROLES_ENUM.PREMIUM,ROLES_ENUM.TEST],
    /*Cambiar contraseña */ CHANGE_PASSWORD:[ROLES_ENUM.NORMAL, ROLES_ENUM.PREMIUM,ROLES_ENUM.TEST,ROLES_ENUM.MANAGER,ROLES_ENUM.SALES],
    /*Asesor digital*/ ADVISER_OPTION:[ ROLES_ENUM.TEST],
    /*Alerta stock*/ALERT_OPTION:[ROLES_ENUM.TEST,ROLES_ENUM.NORMAL , ROLES_ENUM.PREMIUM,ROLES_ENUM.MANAGER],
    /*Asociar cuenta*/  ADD_USER:[ROLES_ENUM.TEST,ROLES_ENUM.PREMIUM],
    /*Cerrar Sesión*/CLOSE_SESSION:[ROLES_ENUM.NORMAL, ROLES_ENUM.PREMIUM,ROLES_ENUM.TEST,ROLES_ENUM.MANAGER,ROLES_ENUM.SALES],
    /*Vender y pedidos*/VENDER_OPTION:            [ROLES_ENUM.NORMAL , ROLES_ENUM.PREMIUM,ROLES_ENUM.TEST,ROLES_ENUM.MANAGER,ROLES_ENUM.SALES],
    /**/PRODUCT_REGISTER_OPTION:[ROLES_ENUM.NORMAL , ROLES_ENUM.PREMIUM,ROLES_ENUM.TEST,ROLES_ENUM.MANAGER],
    INFORMATION_OPTION:[ROLES_ENUM.TEST]
   
    /*ORDERS_OPTION:            [ROLES_ENUM.NORMAL , ROLES_ENUM.PREMIUM,ROLES_ENUM.TEST,ROLES_ENUM.MANAGER,ROLES_ENUM.SALES],*/
    
    /*GOALS_OPTION:            [ROLES_ENUM.NORMAL , ROLES_ENUM.PREMIUM,ROLES_ENUM.TEST,ROLES_ENUM.MANAGER],*/
    //BALANCE_OPTION:            [ROLES_ENUM.TEST],
   
}