import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }

  minLengthArr( number:number ){
    return (c: AbstractControl): {[key: string]: any} => {
      if (c.value.length >= number )
          return null;

      return { 'minLengthArray': true};
  }
  }


  static nonZero(control:FormControl  ):{ [key: string]: any; } {
    ////console.log("ZZ: ", control.value );
    

    if  ( Number(control.value) < 0) {
      //return {nonZero: true};
      return null;
    } else {
      return null;
    }

  }






  static integerNumbers(control:FormControl  ):{ [key: string]: any; } {
    let expression = /^[0-9+e]+$/;
    let number =control.value && control.value
  //console.log("numerillo: ", number);
    

    if (  number && !expression.test( number ) ) {
       ////console.log("vamo");
      return {nonInterger: true};
    } else {
      ////console.log("oe que");
      return null;
    }

    
    /* if( !expression.test(email)){
      //console.log("El email no es valido");
      
			return false;
		} */

  }

  static telephoneNumbers(control:FormControl  ):{ [key: string]: any; } {
    let expression = /^[0-9*#]+$/;
    let number =control.value && control.value
    ////console.log("numerillo: ", number);
    

    if (  number && !expression.test( number ) ) {
       ////console.log("vamo");
      return {nonInterger: true};
    } else {
      ////console.log("oe que");
      return null;
    }

    
    /* if( !expression.test(email)){
      //console.log("El email no es valido");
      
			return false;
		} */

  }


  quantity( number:number ){

    return (c: AbstractControl): {[key: string]: any} => {

   //   //console.log("valor numerico: ", c.value );
      
      
      let string =c.value && c.value.toString();
      
      //999999999999999
      //999999999999999

      //999999999 999999

      //9999999999999999//debe ser este
      //10000000000000000//pero se redodena a este

      let counter = 0;

      if( string ){
        let stringObjest = new  String(string);
        counter =  stringObjest.length;
        
      }
      
      
      if (counter && counter > number ){
        return { 'quantity': true};
      }
        

      return null
  }
  }

   lessEqualThan(form:FormGroup ):ValidatorFn {

    const price = form.controls['N_PRICE'].value;
    const cost = form.controls['N_COST'].value;
    //console.log("price: ", price);
    //console.log("cost: ", cost);
    //console.log("Costo es mayor a price: ",  Number(cost) > Number(price) );
    
    

    if( price == null || cost == null || price == '' || cost == '' ){
      //console.log("nulll");
      if (form.controls['N_COST'].hasError('lessThanEqualTo')) {
        delete form.controls['N_COST'].errors['lessThanEqualTo'];
        form.controls['N_COST'].updateValueAndValidity();
      }
      
      return null;
    }

    

    if( Number(cost) > Number(price) ){
      //console.log("1");
      
      form.controls['N_COST'].setErrors({'lessThanEqualTo': true});


    }else{
      //console.log("2");
      if (form.controls['N_COST'].hasError('lessThanEqualTo')) {
        delete form.controls['N_COST'].errors['lessThanEqualTo'];
        form.controls['N_COST'].updateValueAndValidity();
      }

    }

    

   // return null;

  }


   lessEqualThanStock(form:FormGroup ):ValidatorFn {

    const quanti = form.controls['N_QUANTIBEFORE'].value;
    const quantiadd = form.controls['N_QUANTIADD'].value;
    //console.log("quanti: ", quanti);
    //console.log("QUANTIADD: ", QUANTIADD);
    //console.log("QUANTIADDo es mayor a quanti: ",  Number(QUANTIADD) > Number(quanti) );
    
    

    if( quanti == null || quantiadd == null || quanti == '' || quantiadd == '' ){
      //console.log("nulll");
      if (form.controls['N_QUANTIADD'].hasError('lessThanEqualToStock')) {
        delete form.controls['N_QUANTIADD'].errors['lessThanEqualToStock'];
        form.controls['N_QUANTIADD'].updateValueAndValidity();
      }
      
      return null;
    }

    

    if( Number(quantiadd) > Number(quanti) ){
      //console.log("1");
      
      form.controls['N_QUANTIADD'].setErrors({'lessThanEqualToStock': true});


    }else{
      //console.log("2");
      if (form.controls['N_QUANTIADD'].hasError('lessThanEqualToStock')) {
        delete form.controls['N_QUANTIADD'].errors['lessThanEqualToStock'];
        form.controls['N_QUANTIADD'].updateValueAndValidity();
      }

    }

    

   // return null;

  }

   offerGreaterPrice(form:FormGroup ):ValidatorFn {

    const price = form.controls['N_PRICE'].value;
    const offer = form.controls['N_PRICEOFER'].value;
    //console.log("price: ", price);
    //console.log("cost: ", offer);
    //console.log("Costo es mayor a price: ",  Number(offer) > Number(price) );
    
    

    if( price == null || offer == null || price == '' || offer == '' ){
      //console.log("nulll");
      if (form.controls['N_PRICEOFER'].hasError('greaterthan')) {
        delete form.controls['N_PRICEOFER'].errors['greaterthan'];
        form.controls['N_PRICEOFER'].updateValueAndValidity();
      }

      return null;
    }

    

    if( Number(offer) >= Number(price) ){
      //console.log("1");
      
      form.controls['N_PRICEOFER'].setErrors({'greaterthan': true});


    }else{
      //console.log("2");
      if (form.controls['N_PRICEOFER'].hasError('greaterthan')) {
        delete form.controls['N_PRICEOFER'].errors['greaterthan'];
        form.controls['N_PRICEOFER'].updateValueAndValidity();
      }

    }

    

   // return null;

  }



  passwordIguales( form:FormGroup ):ValidatorFn{
    
      const firstPassControl = form.controls['C_PASS'];
      const secondPassControl = form.controls['C_PASSR'];

     // //console.log("primer input", firstPassControl.value);
     // //console.log("seguncdo input", secondPassControl.value);


      if ( firstPassControl.value !==  secondPassControl.value ) {
        secondPassControl.setErrors({ConfirmPassword: true});
      } else {
       // //console.log("nulllll");
       secondPassControl.setErrors(null);
        return null;
      }
  
  }


}