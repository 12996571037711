import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { PopInfoComponent } from './pop-info/pop-info.component';
import { PopDevolutionComponent } from './pop-devolution/pop-devolution.component';
import { SlideButtonComponent } from './slide-button/slide-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewCategoryComponent } from './new-category/new-category.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { PipesModule } from '../pipes/pipes.module';
import { ListProductsComponent } from './list-products/list-products.component';
import { PopGoalComponent } from './pop-goal/pop-goal.component';
import { OrderListComponent } from './order-list/order-list.component';
import { PopCreditComponent } from './pop-credit/pop-credit.component';
import { MultiStepComponent } from './multi-step/multi-step.component';
import { ChatContentComponent } from './chat-content/chat-content.component';
import { ChatItemComponent } from './chat-item/chat-item.component';
import { ClientListComponent } from './client-list/client-list.component';
import { PhotoOptionComponentComponent } from './photo-option-component/photo-option-component.component';


@NgModule({
  declarations: [
    PopInfoComponent,
    PopGoalComponent,
    PopDevolutionComponent,
    SlideButtonComponent,
    NewCategoryComponent,
    OrderDetailComponent,
    ListProductsComponent,
    OrderListComponent,
    PopCreditComponent,
    MultiStepComponent,
    ChatContentComponent,
    ChatItemComponent,
    ClientListComponent,
    PhotoOptionComponentComponent
  ],
  exports: [
    PopInfoComponent,
    PopGoalComponent,
    PopDevolutionComponent,
    SlideButtonComponent,
    NewCategoryComponent,
    OrderDetailComponent,
    ListProductsComponent,
    OrderListComponent,
    PopCreditComponent,
    MultiStepComponent,
    ChatContentComponent,
    ChatItemComponent,
    ClientListComponent,
    PhotoOptionComponentComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule
  ]
})
export class ComponentsModule { }
