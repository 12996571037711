import { isDelegatedFactoryMetadata } from '@angular/compiler/src/render3/r3_factory';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import * as moment from 'moment'
import { element } from 'protractor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatabaseService } from 'src/app/services/database.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss'],
})
export class ChatItemComponent implements OnInit, OnDestroy {
  moment:any = moment;
  menuOptions = [];
  menuText = [];
  editMessage = [];
  newMessage = [];
  isWait = []
  numberOfChats = 5
  @Input() messages:Array<any>
  unsubscribeSignal: Subject<void> = new Subject();
  constructor(
    private dataBaseService:DatabaseService,
    private toastController:ToastController
  ) { }

  ngOnInit() {

    this.messages.forEach( (element)=>{
     // console.log("elementtc: ", element);
      

      if( element.C_MSMTYPE == '1' ){

        let menu = JSON.parse( element.C_MESSAGE );

        this.menuOptions[element.ID]=menu.options;
        this.menuText[ element.ID ]=menu.message;
        console.log("MenuTeXT: ",this.menuText[ element.ID ] );
        
      }
      this.editMessage[element.ID]=false;

    } )

  }


  ngOnDestroy(){
   this. menuOptions = [];
    this.menuText = [];
    this.editMessage = [];
    this.newMessage = [];

    this.unsubscribeSignal.next();
    this.unsubscribeSignal.complete();
  }



  getAnswer( data:any , message:any ){
   let dataToSend = {
     optionId:data.ID,
     idChat:message.IDCHAT,
     idWorkflow:message.IDWORKFLOW
   }
   this.dataBaseService.sendClickTogetResponseFromBoot( dataToSend );


  }


  editChat( message:any ){

    this.editMessage[message.ID]=true;
    this.newMessage[message.ID]=message.C_MESSAGE


  }


  closeEdit( id:string ){

    this.editMessage[  id ] = false;
    this.newMessage[ id ] = null

  }


  sendEditChat( message:any  ){

    /* console.log("Id del chat a editar: ", message.IDCHAT);
    console.log("Id del padre chat: ",  message.ID );
    console.log("MEssages: ", this.messages ); */

    let request = new FormData();
    request.set( 'IDCHAT', message.IDCHAT )
    request.set( 'ID', message.ID )
    request.set( 'C_MESSAGE',  this.newMessage[ message.ID] )
    request.set( 'IDCOMPANY', message.IDCOMPANY )
    request.set( 'D_DATE', message.D_DATE )

    this.isWait[ message.ID ] = true
    this.dataBaseService.editChat(request)
    .pipe(takeUntil(this.unsubscribeSignal.asObservable()))
    .subscribe( ( rpta:any )=>{
      console.log("Se actualizó: ", rpta);
      this.isWait[ message.ID ] = false;
      if( rpta.status == 'true' ){

        let updateArr = [];
        this.messages.slice().forEach( (element)=>{
    
          if( element.ID ==  message.ID){
            console.log("actualiuzar este elemento: ",element );
            let newObject = {
              'C_MESSAGE': this.newMessage[ message.ID],
              'C_MSMTYPE': element.C_MSMTYPE ,
              'C_STATUS': element.C_STATUS,
              'C_TYPE': element.C_TYPE,
              'D_DATE': element.D_DATE,
              'ID': element.ID ,
              'IDCHAT': element.IDCHAT,
              'IDCOMPANY': element.IDCOMPANY,
              'IDWORKFLOW': element.IDWORKFLOW,
              'fecha': element.fecha,
            }
            updateArr.push(newObject);
    
          }else{
            updateArr.push(element)
    
          }
    
        } )
        this.messages = updateArr;
        this.editMessage[  message.ID ] = false;
        this.newMessage[ message.ID ] = null

        return;
      }

      this.presentToast("Error al tratar de editar el mensaje");
      return;

    }, err=>{ this.isWait[ message.ID ] = false } )

   





  }































  



  async presentToast(msm: string) {
    const toast = await this.toastController.create({
      message: msm,
      position: 'middle',
      duration: 2000,
      color: 'success',
    });
    toast.present();
  }



}
