
import { InterpageService } from "../services/interpage.service";
import { Injectable } from "@angular/core";
import { Resolve , ActivatedRouteSnapshot } from "@angular/router";
import { Observable , } from "rxjs";
import { tap , } from "rxjs/operators";
import { LoadingController } from "@ionic/angular";

@Injectable({
    providedIn:"root"
})
export class CreditNoteResolver implements Resolve<any>{
    constructor(
        public interPageService : InterpageService,
        public loadingCtrl:LoadingController 
    ){}


loader : any;
    resolve( route:ActivatedRouteSnapshot   ){

     
/*         this.loadingCtrl.create({
            message : "espere porfavor..."
        }).then( (res)=>{
            loading = res;
            loading.present();
        } )
  */


     return this.interPageService.getheaderAndCreditNote()
        
        

    }


    
  async presentLoader(options: any = {}) {
    this.loader = await this.loadingCtrl.create({
        message : "Espere..."
    });
    return this.loader.present();
  }

  async dismissLoader() {
      await this.loader.dismiss()
      .then(()=>{
        this.loader = null;
      })
      .catch(e => console.log(e));
  }
}