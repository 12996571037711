import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil , map } from 'rxjs/operators';
import { DatabaseService } from 'src/app/services/database.service';
import { InterpageService } from 'src/app/services/interpage.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.scss'],
})
export class ListProductsComponent implements OnInit, OnDestroy {
  // public products=[];
  @Input() saleProcess;
  @Input() idHeaderSale;
  showFigure: boolean = null;
  load: any = true;
  @Input() searchText = '';
  @Input('products') products: Observable<any>;
  listOfProducts = [] ;
  componentSubscription: Subscription;
  subscription: Subscription;
  clickToErrorHttpSubs: Subscription;
  interPageSubs1: Subscription;
  interPageSubs2: Subscription;
  unsubscribeSignal: Subject<void> = new Subject();
  component = '';
  searched: boolean;
  constructor(
    private _dataService: DatabaseService,
    private interpageService: InterpageService,
    private router: Router,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) {
    console.log('COmponen PRoductsss');
  }

  ngOnInit() {
    //this.load = true;
    this.clickToErrorHttpSubs = this._dataService
      .getClickToErrorHttp()
      .subscribe(() => {
        console.log('main page error!!!');
        //this.isWait = false;

        this.loaderService.hide();
      });

    //this.loadAllProducts();

    /*  this.componentSubscription = this._dataService.getClickToProductsCompo().subscribe( ()=>{
      console.log("__Will enter product compo");
       this.loadAllProducts();
    } ) */


    /* setTimeout(() => {
  let products = [];
  console.log("products(componente lsit): ",this.showFigure );
  this.load = false;
this.listOfProducts = products;
this.showFigure = products.length>0?true:false;
//this.load = false; 
this.cdr.detectChanges(); 
  
}, 6000); */

    this.subscription = this.products.subscribe((products) => {
      console.log('products(componente lsit): ', products);
      this.showFigure = products[0].product.length == 0 ? false:true;
      this.load = products[0].load;
      this.listOfProducts = products[0].product;
      this.component = products[0].component;

      if( products[0].search ){
        this.searched = true;
        this.load = true;

      }else{
        this.searched = false;
        this.load = products[0].load;
      }

      /* if (products[0]?.product != null) {
        if (products[0].product.length > 0) {

          this.listOfProducts = products;
          this.showFigure = true;
          this.load = false;
          // this.cdr.detectChanges();
        } else if (products[0].product.length == 0) {
          this.listOfProducts = [];
          this.showFigure = false;
          this.load = false;
          //this.cdr.detectChanges();
        } else {
        }
        localStorage.setItem(
          environment.numberprods,
          this.listOfProducts.length.toString()
        );
      } else {
        this.load = true;
        this.showFigure = null;
      } */
    });
  }

  ionViewWillEnter() {
    console.log('__listWILL__');
    this.searched = false;
  }

  ngOnDestroy() {
    console.log('ListProdsCompo Destroy');
    this.listOfProducts = null;
    this.load = true;
    this.showFigure;
    this.clickToErrorHttpSubs.unsubscribe();
    this.unsubscribeSignal.next();
    this.unsubscribeSignal.complete();
  }

  navigateTonewProduct() {
    let idProductToEdit = null;

    const data = {
      idProductToEdit:null,
      tab:'ITEMS'
    }

    this.interpageService
      .setIdProductToEdit(data)
      .subscribe((rpta) => {
        console.log('idP Seteado?: ', rpta);

        this.router.navigate(['product-register']);
      });
  }

  toEditProduct(element) {
    console.log('___Estom se vendera___: ', element);
    console.log('idHeaderSale: ', this.idHeaderSale);
    console.log('llgamo: ', this.saleProcess);
    let search = (element.search != null || element.search != undefined)? element.search : '0'
    let codbar = search == '1'?element.toFilter:''

    if (this.saleProcess) {
      console.log('proceso de venta');
      //CCR1397cdf talla cdte


      /* let dataToSale = {
        id_product_to_edit: element.product[0].ID,
        idHeaderSale: this.idHeaderSale,

      }; */

      let dataToSale = {
        id_product_to_edit: element.product[0].ID,
        idHeaderSale: this.idHeaderSale,
        search: search ,
        N_CODBAR: codbar

      };

      //CCR1397cdf

      console.log('dataToSale go: ', dataToSale);

      this.interPageSubs1 = this.interpageService
        .setDataToSale(dataToSale)
        .pipe(takeUntil(this.unsubscribeSignal.asObservable()))

        .subscribe((rpta) => {
          console.log('dataToSale(Pockproduct): ', rpta);
          this.router.navigate(['sell-product']);
        });

    } else {
      console.log('vamos a actualizar este elemento: ', element.product[0]);

      //let idProductToEdit = element.product[0].ID;
      const data = {
        idProductToEdit:element.product[0].ID,
        tab:'ITEMS'
      }

      this.interPageSubs2 = this.interpageService
        .setIdProductToEdit( data )
        .subscribe((rpta) => {
          //console.log('idP Seteado?: ', rpta);

          this.router.navigate(['product-register']);
        });
    }
  }
}
