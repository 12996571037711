import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state:RouterStateSnapshot
  ): boolean | UrlTree | Observable<UrlTree> {

    let idUser = localStorage.getItem(environment.iduser)
    if( idUser ){
      return true;
    }else{
      const tree: UrlTree = this.router.parseUrl("/");
      return tree;

    }

  }
}
