import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, PopoverController, ToastController } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-pop-goal',
  templateUrl: './pop-goal.component.html',
  styleUrls: ['./pop-goal.component.scss'],
})
export class PopGoalComponent implements OnInit, OnDestroy {
  @Input("idGoal") idGoal;
  delGoalSubs:Subscription;
  unsubscribeSignal: Subject<void> = new Subject();

  constructor(
    private dataBaseService:DatabaseService,
    public toastController: ToastController,
    public alertController: AlertController,
    private popoverController: PopoverController,
    private router:Router,
  ) { }

  ngOnInit() {}




    editGoal(){
      let navigationExtras:NavigationExtras = {
        queryParams:{
          id:this.idGoal
        }
      };

      this.router.navigate(['objectives/new-objective'],navigationExtras ).then( ()=>{

        this.popoverController.dismiss();
      } )
    }


    ngOnDestroy(){
     // this.delGoalSubs.unsubscribe();
      this.unsubscribeSignal.next();
      this.unsubscribeSignal.complete();
    }


  deleteGoals(){

    let request = {
      ID:this.idGoal
    }
  
    this.delGoalSubs = this.dataBaseService.delGoals(request)
    .pipe( 
      takeUntil( this.unsubscribeSignal.asObservable() )
      )
    .subscribe( (del:any)=>{
      if( del.status == 'true' ){
        this.presentToast("Se borró correctamiente");
        return;
      }
      this.presentToast("error al Eliminar");
      });
  
  }
  



  async presentToast(msm) {
    const toast = await this.toastController.create({
      message: msm,
      duration: 2000,
      position: "bottom",
      color:"danger"
    });
    toast.present();
  }


  
  async presentAlertConfirm( ) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Eliminar Objetivo',
      message: `¿Esta seguro de Eliminar el objetivo?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            
        this.popoverController.dismiss();
          }
        }, {
          text: 'Sí,Eliminar',
          handler: () => {
            this.deleteGoals()
          }
        }
      ]
    });

    await alert.present();
  }


}
