import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-content',
  templateUrl: './chat-content.component.html',
  styleUrls: ['./chat-content.component.scss'],
})
export class ChatContentComponent implements OnInit {
  @Input() chats:Array<any>;
  @Input() isPageScrolling = false;

  constructor() { }

  ngOnInit() {}


  getDate( element ):string{
   // console.log("Element: ", element);
    
    let ki = Object.keys( element )[0]
    //console.log("KI: ", ki);
    
    return ki;
  }


}
