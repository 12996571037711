import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { ToastController } from '@ionic/angular';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(
    public toastController: ToastController,
    public databaseuser: DatabaseService,
    private router:Router
  ) {


   }



  initPush(){
    console.log("init fire");
   let ola = Capacitor.getPlatform();
   console.log("OLAA: ", ola );
    
    if( Capacitor.getPlatform() !== 'web' ){
      console.log("Shadows ");
      let platform = Capacitor.getPlatform();
      this.registerPush( platform );
    }
  } 



  private registerPush(plat){

    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
   /*  PushNotifications.addListener('registration',
      (token: Token) => {
        alert('Push registration success, token: ' + token.value + "plat: " + plat  );
        let iduser=localStorage.getItem('iduser');
        let request={
          'TOKFCM':token,
          'IDUSER':iduser
        }
        this.databaseuser.setTokenFCM(request).subscribe(data => {
          console.log(data);
        });
      }
    ); */

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
       // alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        //alert('Push received: ' + JSON.stringify(notification));

          let message=notification.title;
            this.presentToast(JSON.stringify(message));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log("tapping en la notificacion");
        this.router.navigate(['pending-finished'])
        //let alert('Push action performed: ' + JSON.stringify(notification));
        //let message=notification.title+', '+notification.body;
        //this.presentToast(JSON.stringify(message));
      }
    );

  }

  
  async presentToast(msm: string) {
    const toast = await this.toastController.create({
      message: msm,
      position: "top",
      duration: 5000,
      color:"success"
    });
    toast.present();
  }

}
