import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { DataStoreGuard } from './guards/data-store.guard';
import { NotLoginGuard } from './guards/not-login.guard';
import {  } from './guards/operation.guard';
import { PrePaymentGuard } from './guards/prepayment.guard';
import { TypeSaleGuard } from './guards/type-sale.guard';
import { UponPaymentGuard } from './guards/uponpayment.guard';
import { WizardGuard } from './guards/wizar.guard';
import { CartResolver } from './resolver/cart-resolver.service';
import { CreditNoteResolver } from './resolver/creditnote-resolver.service';
import { InterPageResolver } from './resolver/interpage-resolver.service';
import { LoginResolver } from './resolver/login-resolver.service';
import { NewClientResolver } from './resolver/newclient-resolver.service';
import { ProductRegisterResolver } from './resolver/productRegister-resolver.service';
import { ProductsResolver } from './resolver/products-resolver.service';
import { ReceiptResolver } from './resolver/receipt-resolver.service';
import { SellProductResolver } from './resolver/sellProduct-resolver.service';
import { RESOURCE_BY_ROLES } from './uiModel/internalRoutes';

const routes: Routes = [

  {
    path: 'enter-options',
    loadChildren: () =>
      import('./pages/enter-options/enter-options.module').then(
        (m) => m.EnterOptionsPageModule
      ),
    resolve: { mailToLogin: LoginResolver },
  },

  {
    path: 'login',
    canActivate: [NotLoginGuard],
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
    resolve: { mailToLogin: LoginResolver },
  },
  {
    path: 'bubblemenu',
    canActivate: [],
    loadChildren: () =>
      import('./bubblemenu/bubblemenu.module').then((m) => m.BubblemenuPageModule)
  },
  {
    path: 'prepayment',
    canActivate: [],
    loadChildren: () =>
      import('./pages/prepayment/prepayment.module').then(
        (m) => m.PrepaymentPageModule
      ),
  },
  {
    path: 'uponpayment',
    canActivate: [],
    loadChildren: () =>
      import('./pages/uponpayment/uponpayment.module').then(
        (m) => m.UponpaymentPageModule
      ),
  },
  {
    path: 'main-page',
    canActivate: [
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard,
      
    ],
    loadChildren: () =>
      import('./pages/main-page/main-page.module').then(
        (m) => m.MainPagePageModule
      ),
  },

  {
    path: 'settings',
    canActivate: [

      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'data-store',

    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/data-store/data-store.module').then(
        (m) => m.DataStorePageModule
      ),
  },
  {
    path: 'social-networks',

    canActivate: [DataStoreGuard, AuthGuard],
    loadChildren: () =>
      import('./pages/social-networks/social-networks.module').then(
        (m) => m.SocialNetworksPageModule
      ),
  },
  {
    path: 'products',
    canActivate: [
     AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard,
      

      
      
    ],
    loadChildren: () =>
      import('./pages/products/products.module').then(
        (m) => m.ProductsPageModule
      ),
      resolve:{ dataProducts:ProductsResolver }
  },
  {
    path: 'product-register',
    canActivate: [
      DataStoreGuard,
      AuthGuard
    ],
    loadChildren: () =>
      import('./pages/product-register/product-register.module').then(
        (m) => m.ProductRegisterPageModule
      ),
    resolve: { idProductToEdit: ProductRegisterResolver },
    data: { roles: RESOURCE_BY_ROLES.PRODUCT_REGISTER_OPTION },
  },

  {
    path: 'clients',  
    loadChildren: () =>
      import('./pages/clients/clients.module').then((m) => m.ClientsPageModule),
      canActivate: [
        DataStoreGuard,
        AuthGuard
 
      ],
  },
  {
    path: "client",

    canActivate: [
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
    loadChildren: () =>
      import('./pages/client/client.module').then((m) => m.ClientPageModule),
  },
  {
    path: 'newclient',
    canActivate: [DataStoreGuard, AuthGuard],
    loadChildren: () =>
      import('./pages/newclient/newclient.module').then(
        (m) => m.NewclientPageModule
      ),
      resolve:{ dataToNewClient: NewClientResolver }
  },
  {
    path: 'sell',

    loadChildren: () =>
      import('./pages/sell/sell.module').then((m) => m.SellPageModule),
      canActivate: [
        DataStoreGuard,
        AuthGuard
 
      ],
    data: { roles: RESOURCE_BY_ROLES.VENDER_OPTION },
  },

  {
    path: 'cart',
    canActivate: [DataStoreGuard, AuthGuard],
    loadChildren: () =>
      import('./pages/cart/cart.module').then((m) => m.CartPageModule),
      resolve: { dataToCart: CartResolver },
  },
  {
    path: 'payments',
    canActivate: [DataStoreGuard, AuthGuard],
    loadChildren: () =>
      import('./pages/payments/payments.module').then(
        (m) => m.PaymentsPageModule
      ),
  },
  {
    path: 'payment-success',

    canActivate: [DataStoreGuard, AuthGuard],
    resolve: {
      headerSaleInfo: InterPageResolver,
    },
    loadChildren: () =>
      import('./pages/payment-success/payment-success.module').then(
        (m) => m.PaymentSuccessPageModule
      ),
  },
  {
    path: 'receipt',
    resolve: {
      idHeaderSale: ReceiptResolver,
    },
    canActivate: [DataStoreGuard, AuthGuard],
    loadChildren: () =>
      import('./pages/receipt/receipt.module').then((m) => m.ReceiptPageModule),
  },
  {
    path: 'sell-product',
    loadChildren: () =>
      import('./pages/sell-product/sell-product.module').then(
        (m) => m.SellProductPageModule
      ),
    resolve: { dataToSale: SellProductResolver },
    canActivate: [AuthGuard],
  },
  {
    path: 'user-register',
    loadChildren: () =>
      import('./pages/user-register/user-register.module').then(
        (m) => m.UserRegisterPageModule
      ),
    resolve: { mailToLogin: LoginResolver },
  },
  {
    path: 'new-category',
    canActivate: [DataStoreGuard, AuthGuard],
    loadChildren: () =>
      import('./pages/new-category/new-category.module').then(
        (m) => m.NewCategoryPageModule
      ),
  },
  {
    path: 'credit-note',
    loadChildren: () =>
      import('./pages/credit-note/credit-note.module').then(
        (m) => m.CreditNotePageModule
      ),
    resolve: {
      headerAndCreditNote: CreditNoteResolver,
    },
    canActivate: [AuthGuard, DataStoreGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./pages/orders/orders.module').then((m) => m.OrdersPageModule),
      canActivate: [
        DataStoreGuard,
        AuthGuard
      
        
      ],
  },

  {
    path: 'catalog',
          canActivate: [
            AuthGuard,
            DataStoreGuard,
            TypeSaleGuard,
            UponPaymentGuard,
            PrePaymentGuard
          ],
    loadChildren: () =>
      import('./pages/catalog/catalog.module').then((m) => m.CatalogPageModule),

    
  },
  {
    path: 'orders-configuration',
    loadChildren: () =>
      import('./pages/orders-configurations/orders-configurations.module').then(
        (m) => m.OrdersConfigurationsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'enter-mail',
    loadChildren: () =>
      import('./pages/enter-options/enter-mail.module').then(
        (m) => m.EnterMailPageModule
      ),
  },
  {
    path: 'pick-products',
    loadChildren: () =>
      import('./pages/pick-products/pick-products.module').then(
        (m) => m.PickProductsPageModule
      ),
      canActivate: [
        AuthGuard,
        DataStoreGuard,
        TypeSaleGuard,
        UponPaymentGuard,
        PrePaymentGuard
      ],
    resolve: { dataToPickProduct: CartResolver }, //oni4en elidHeaderSale
  },
  {
    path: 'select-typesal',
    loadChildren: () =>
      import('./pages/select-typesal/select-typesal.module').then(
        (m) => m.SelectTypesalPageModule
      ),
    resolve: { dataToTypeSale: CartResolver },
  },

  {
    path: 'payment-configurations',
    loadChildren: () =>
      import(
        './pages/payment-configurations/payment-configurations.module'
      ).then((m) => m.PaymentConfigurationsPageModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'select-typesal',
    
    loadChildren: () =>
      import(
        './pages/select-typesal/select-typesal.module'
      ).then((m) => m.SelectTypesalPageModule),
    canActivate: [AuthGuard],
  },




  {
    path: 'objectives',
    canActivate: [
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
    loadChildren: () =>
      import('./pages/objectives/objectives.module').then(
        (m) => m.ObjectivesPageModule
      ),
  },

  {
    path: 'expense-income',
    canActivate: [
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
    loadChildren: () =>
      import('./pages/expense-income/expense-income.module').then(
        (m) => m.ExpenseIncomePageModule
      ),
  },
  {
    path: 'my-expenses',
    loadChildren: () => import('./pages/my-expenses/my-expenses.module').then( m => m.MyExpensesPageModule),
    canActivate: [
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
  },
  {
    path: 'min-stock-modal',
    canActivate: [
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
    loadChildren: () => import('./pages/main-page/min-stock-modal/min-stock-modal.module').then( m => m.MinStockModalPageModule)
  },
  {
    path: 'pending-finished',
    loadChildren: () => import('./pages/pending-finished/pending-finished.module').then( m => m.PendingFinishedPageModule),
    canActivate: [
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
  },
  {
    path: 'chat',
    canActivate:[
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'my-clients',
    canActivate:[
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
    loadChildren: () => import('./pages/my-clients/my-clients.module').then( m => m.MyClientsPageModule)
  },
  {
    path: 'type-sale-conf',
    loadChildren: () => import('./pages/typesale-configuration/typesale-configuration.module').then( m => m.TypesaleConfigurationPageModule)
  },
  {
    path: 'presentation',
    loadChildren: () => import('./presentation/presentation.module').then( m => m.PresentationPageModule)
  },
  {
    path: 'configurations',
    loadChildren: () => import('./configurations/configurations.module').then( m => m.ConfigurationsPageModule)
  },
  {
    path: 'operation',
    loadChildren: () => import('./operation/operation.module').then( m => m.OperationPageModule)
  },
  {
    path: 'sale-process',
    loadChildren: () => import('./sale-process/sale-process.module').then( m => m.SaleProcessPageModule)
  },
  {
    path: 'store-help',
    loadChildren: () => import('./pages/data-store/store-help/store-help.module').then( m => m.StoreHelpPageModule)
  },
  {
    path: 'typesale-help',
    loadChildren: () => import('./pages/typesale-configuration/typesale-help/typesale-help.module').then( m => m.TypesaleHelpPageModule)
  },
  {
    path: 'upon-help',
    loadChildren: () => import('./pages/uponpayment/upon-help/upon-help.module').then( m => m.UponHelpPageModule)
  },
  {
    path: 'prepayment-help',
    loadChildren: () => import('./pages/prepayment/prepayment-help/prepayment-help.module').then( m => m.PrepaymentHelpPageModule)
  },
  {
    path: 'help-catalog',
    loadChildren: () => import('./pages/catalog/help-catalog/help-catalog.module').then( m => m.HelpCatalogPageModule)
  },
  {
    path: 'help-newclient',
    loadChildren: () => import('./pages/newclient/help-newclient/help-newclient.module').then( m => m.HelpNewclientPageModule)
  },
  {
    path: 'help-stock',
    loadChildren: () => import('./pages/product-register/help-stock/help-stock.module').then( m => m.HelpStockPageModule)
  },
  {
    path: 'help-mainpage',
    loadChildren: () => import('./pages/main-page/help-mainpage/help-mainpage.module').then( m => m.HelpMainpagePageModule)
  },
  {
    path: 'balance',
    canActivate: [
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard,
      
    ],
    loadChildren: () => import('./pages/balance/balance.module').then( m => m.BalancePageModule)
  },
  {
    path: 'box-money',
    canActivate: [
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard,
      
    ],
    loadChildren: () => import('./pages/box-money/box-money.module').then( m => m.BoxMoneyPageModule)
  },
    {path: 'entry-modal',
    loadChildren: () => import('./pages/product-register/entry-modal/entry-modal.module').then( m => m.EntryModalPageModule)
  },
  {
    path: 'outs-modal',
    loadChildren: () => import('./pages/product-register/outs-modal/outs-modal.module').then( m => m.OutsModalPageModule)
  },
  {
    path: 'reports',
    canActivate:[
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'associate-accounts',
    canActivate:[
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
    loadChildren: () => import('./pages/associate-accounts/associate-accounts.module').then( m => m.AssociateAccountsPageModule)
  },
  {
    path: 'inform-yourself',
    canActivate:[
      AuthGuard,
      DataStoreGuard,
      TypeSaleGuard,
      UponPaymentGuard,
      PrePaymentGuard
    ],
    loadChildren: () => import('./pages/inform-yourself/inform-yourself.module').then( m => m.InformYourselfPageModule)
  },










];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
