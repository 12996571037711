import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loader;

  constructor(public loadingController: LoadingController) { 
    this.loader = null;
  }


  async show(message?: string) {

    return this.loadingController.getTop().then( async load =>{
        if( !load ){
          const loading = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: 'Cargando...',
            //duration: 2000
          });
         return  await loading.present();

        }
    } );
    
  }
  
  hide() {
    this.loadingController.getTop().then( load=>{
        if( load ){
            this.loadingController.dismiss();
        }
    } )
  }

}
