import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pop-devolution',
  templateUrl: './pop-devolution.component.html',
  styleUrls: ['./pop-devolution.component.scss'],
})
export class PopDevolutionComponent implements OnInit {
  @Input("product") discountProd
  constructor() { }

  ngOnInit() {

    console.log("pop devolver info: " , this.discountProd );
    
    
  }





returnProduct(){


}

}
