import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild , Output, EventEmitter, ChangeDetectorRef, SimpleChanges, OnChanges} from '@angular/core';
import { IonButton, IonContent } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';



@Component({
  selector: 'app-slide-button',
  templateUrl: './slide-button.component.html',
  styleUrls: ['./slide-button.component.scss'],
})
export class SlideButtonComponent implements OnInit , AfterViewInit,OnChanges {
  @Input() statussale:string
  @Input() slideButtonInstructions:string;
  @Input() slideButtonHelperText:string;
  @Output() newItemEvent = new EventEmitter<string>();
  @ViewChild('ContainerButton' , { read:ElementRef } ) ContainerButton:IonContent;
  @ViewChild('button' , { read:ElementRef } ) button:ElementRef;
  buttonText;

  isPressed:boolean = false;
  private clickPosition:any
  xMax:number = 260; //width button container;
  slideButtonDone: boolean;

  @Input() events: Observable<void>;
  private eventsSubscription: Subscription;

//https://medium.com/@JSantaCL/ionic-slide-button-component-part-2-5f7b0b1770f3
  constructor(
    public renderer:Renderer2,
    public cdr:ChangeDetectorRef,
  ) { 

    

  }

  ngOnInit() {

console.log("statussale: ", this.statussale );

    
    
      this.eventsSubscription = this.events.subscribe(( statussale ) =>{
      console.log("Rcibimos la emicion: ", statussale );
      
      this.slideButtonDone=false;
      this.resetButton()  } );

  }

  ngOnChanges(changes: SimpleChanges) {
        if( changes.statussale && changes.statussale.currentValue ){
          console.log("statussale22: ", changes.statussale.currentValue);
          if( changes.statussale.currentValue != "Deslice para continuar" ){
            //this.slideButtonActive(  )
          }
        
          

        }

    // You can also use categoryId.previousValue and 
    // categoryId.firstChange for comparing old and new values
    
}

slideButtonActive(){
  console.log("Poner El boton al fnial!!!!");

  let posCss = {
    "transform": "translateX(" + 226 + "px)",
      "-webkit-transform": "translateX(" + 226 + "px)"
  }

  this.renderer.setStyle( this.button.nativeElement , 'transform' , posCss['transform']  )
  this.renderer.setStyle( this.button.nativeElement , '-webkit-transform' , posCss['-webkit-transform']  )

  
}

  ngAfterViewInit(){

    console.log("Btton TExt: ", this.buttonText );
    let xMax = document.getElementById("container-button") as HTMLElement ;

    
  }

  activateBg(e) {
    this.isPressed = true;
    this.clickPosition = e['center'];
    
  }

  deactivateBg(){
    this.isPressed = false;
  }



  dragButton( e ){ 
    
    let xFijo:number = e['center'].x;
   
    
    

    let xDesplacamiento = this.isPressed?xFijo - this.clickPosition.x : 0;
    //let xDesplacamiento = this.isPressed?xFijo : 0;

    let posCss = {
      "transform": "translateX(" + xDesplacamiento + "px)",
        "-webkit-transform": "translateX(" + xDesplacamiento + "px)"
    }

    //
    if( xDesplacamiento >= 0 && xDesplacamiento < 226 && this.isPressed  ){
      console.log("desplacamiento: ", xDesplacamiento);
      

      this.renderer.setStyle( this.button.nativeElement , 'transform' , posCss['transform']  )
      this.renderer.setStyle( this.button.nativeElement , '-webkit-transform' , posCss['-webkit-transform']  )

      
    }


    if( !(xDesplacamiento >= 0 && xDesplacamiento <226&& this.isPressed)  ){
      //cuando se sale del borde se ejecuta el slide button
      console.log("desplacamiento FUERA: ", xDesplacamiento);

      if( xDesplacamiento >= 210 ){




        this.isPressed       = true;
        this.slideButtonDone = true;
        this.newItemEvent.emit('hecho!')


        
      }
    }



  }

  



    resetButton( ) {
      // Only reset if button sliding is not done yet
      console.log("vamos a resetar");
      
      if(!this.slideButtonDone) {
        console.log("iniciales");
        
         // Re-enables menu
         
         // Reset state variables
         this.isPressed       = false;
         this.slideButtonDone = false;
         // Resets button position
         let posCss = {
           "transform": "translateX(0px)",
           "-webkit-transform": "translateX(0px)"
         };
         this.renderer.setStyle(
           this.button .nativeElement, 
           'transform', 
           posCss['transform']);
         this.renderer.setStyle(
           this.button.nativeElement, 
           '-webkit-transform', 
           posCss['-webkit-transform']);
      }
  }









}
