import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { InterpageService } from 'src/app/services/interpage.service';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { DatabaseService } from 'src/app/services/database.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit {
  @Input() searchText = '';
  @Input('orders') orders: Observable<any>;
  @Output() idHeaderEmit = new EventEmitter<string>();
  headerSalesByDay = [];
  subscription: Subscription;
  showSkeleton: boolean;
  componentSubscription: Subscription;
  interPageSubs: Subscription;
  deleteHeaderSaleSubs:Subscription;
  setIdHeaderSale:Subscription;
  moment: any = moment;
  unsubscribeSignal: Subject<void> = new Subject();
  constructor(
    private interPageService: InterpageService,
    private router: Router,
    private modalController: ModalController,
    private dataBaseService: DatabaseService,
    private toastController: ToastController,
    private alertController : AlertController
  ) {}

  ngOnInit() {
    this.subscription = this.orders.subscribe((orders) => {
      console.log('ordersList(componente): ', orders);
      this.headerSalesByDay = orders;

      //this.showSkeleton = orders.length>0?true:false;
      //this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    console.log('destroy');
    this.headerSalesByDay = [];
    this.subscription.unsubscribe();
    this.unsubscribeSignal.next();
    this.unsubscribeSignal.complete();
  }

  async openOrderDetail(hs, order) {
    localStorage.setItem("rapidSale","0")
    console.log('HEaderSale(order-list): ', hs);

    if ( hs.c_statussale == '4' ) {

      if( ["3"].includes(hs.C_STATUSPAYMENT) ){
        let data = {
          idHeaderSale: hs.ID,
        };
  
        console.log('Data to recepit : ', data);
  
       this.setIdHeaderSale = this.interPageService.setIdHeaderSale (data)
       .pipe( 
        takeUntil( this.unsubscribeSignal.asObservable() )
        )
       .subscribe(() => {
          this.router.navigate(['payment-success']);
        });
      }else{
        this.openOrderDetailFromModal(hs);
      }

    }else if(  ["1", "2", "3"].includes(hs.c_statussale)  ){
      this.openOrderDetailFromModal(hs);
    }else if( hs.c_statussale == "0" ){
      let dataToSellModal = {
        customer_id: "",
        customerName: "",
        idHeaderSale: hs.ID,
      };
      this.interPageService
        .setDataToSellModal(dataToSellModal)
        .subscribe(() => {
          this.router.navigate(["/sell/sell-modalcito"]);
        });


    }else if ( hs.c_statussale == "-1"  ){

      console.log("En devolucion: " ,  hs.c_statussale);
      

      let idHeaderSale = hs.C_OBSERV.substr(
        hs.C_OBSERV.lastIndexOf(":") + 1
      ); //En devolucion
      let idCreditNote = hs.ID;
      let headerAndCreditNote = {
        idHeaderSale,
        idCreditNote,
      };
      console.log("a gualdal statusale -1: ", headerAndCreditNote);
      this.interPageService
        .setheaderAndCreditNote(headerAndCreditNote)
        .subscribe((data) => {

           console.log("Se guardó!: ", data);
         this.router.navigate(["credit-note"]);
          //this.menuCtrl.close();

        });

    }else if(  hs.c_statussale == "-2"  ){
      let idHeaderSale = hs.C_OBSERV.substr(
        hs.C_OBSERV.lastIndexOf(":") + 1
      );
      let idCreditNote = hs.ID;
      let headerAndCreditNote = {
        idHeaderSale,
        idCreditNote,
      };
      console.log("a gualdar: ", headerAndCreditNote);
      this.interPageService
        .setheaderAndCreditNote(headerAndCreditNote)
        .subscribe((hc) => {
          console.log("Hc: ", hc);
          this.router.navigate(["credit-note"]);
        });
    }else{

      let navigationExtras: NavigationExtras = {
        queryParams: {
          save_sail: false,
        },
      };

      this.router.navigate(["clients"], navigationExtras);
    }
    
    
    


  }



  async openOrderDetailFromModal(hs ) {
    console.log("HEaderSale(Modal): ", hs);

 
          const modal = await this.modalController.create({
          component: OrderDetailComponent,
          cssClass: "my-custom-class",
          componentProps: {
            idheadersale: hs.ID,
            idTypepayment: hs.IDTYPEPAYMENT,
            idCustomer:hs.IDCUSTOMER,
            order: `${hs.IDUNIQUE}${hs.IDCOUNTER}`,
          },
        });

       modal.onWillDismiss().then( ()=>{ 
        this.dataBaseService.sendClickListOfOrders(true);
        } )

        return await modal.present();

 


  }







  getIcon(c_despar):any {
    if( c_despar == "Pedido Realizado"){

      let data = {
        icon:'cart',
        color:'#FF00FF'
      }
      return data;
    }else if( c_despar == "Listo para entregar"){
      let data = {
        icon:'bag',
        color:'#FF9900'
      }
      return data;
    }else if(  c_despar == "En delivery" ){
      let data = {
        icon:'bicycle',
        color:'#FFFF00'
      }
      return data;
    }else if(  c_despar ==  "Entregado" ){
      let data = {
        icon:'checkmark',
        color:'#00FF00'
      }
      return data;
    }else if( c_despar ==  "Anulado"){
      let data = {
        icon:'trash',
        color:'#FF0000'
      }
      return data;
    }else{
       // console.log("N.A.");
        let data = {
          icon:'refresh',
          color:'brown'
        }
        return data;
    }

 
  }

  async deleteAlertConfirm(id) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      //header: 'Confirm!',
      message: '¿Desea Eliminar la venta?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Sí',
          handler: () => {
            this.deleteSale(id);
          }
        }
      ]
    });

    await alert.present();

  }





  deleteSale( id ){

    console.log("Elimiar este hgeaderSale: ", id );

    let request = {
      'IDHEADERSALE' : id
    }

   this.deleteHeaderSaleSubs = this.dataBaseService.deleteHeaderSaleById( request )
   .pipe( 
    takeUntil( this.unsubscribeSignal.asObservable() )
    )
   
   .subscribe( (rpta:any)=>{
      if( rpta.status == 'true' ){

        this.idHeaderEmit.emit(id);
        return;
      }

      this.presentToast("Error al borra la venta");
      return;
    }  )

    

  }



  async presentToast(msm: string) {
    const toast = await this.toastController.create({
      message: msm,
      position: 'middle',
      duration: 2000,
      color: 'success',
    });
    toast.present();
  }

  
}
