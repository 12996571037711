import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orders',
})
export class OrdersPipe implements PipeTransform {
  transform(items: any, filterx: any ): any {
    console.log('Orders Pipe: ', items);

    if (!filterx || !Array.isArray(items)) {
      return items;
    }

    if (filterx && Array.isArray(items)) {
      let filterKeyss = Object.keys(filterx);
      if ( false ) {

      } else {


        let row = items.filter((item) => {


          // console.log("Map: ", item );
          let headerSales = item['headerSales'].filter((headerSale) => {
            return filterKeyss.some((keyName) => {
              return new RegExp(filterx[keyName], 'gi').test(
                headerSale[keyName]
              );
            });
          });




          if (headerSales.length > 0) {
            let selectedItem = {
              ...item,
              headerSales: headerSales,
            };
            // console.log("armani: ", selectedItem );
            return selectedItem;
          }
        });




        let filter = row.filter((e) => e != undefined);
        return filter;
      }
    }




    
  }
}
