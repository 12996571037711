// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  idcompany:'idcompany',
  iduser:'iduser',
  nameuser:'nameuser',
  paymethod:'paymethod',
  paymethods:'paymethods',
  rol:'rol',
  numberprods:'numberprods',
  iddefaultcustomer:'iddefaultcustomer',
  typeSale:'typeSale',
  namcom:'namcom',
  deliverymount:'deliverymount', 
  device_v:'device_v',
  platform:'platform',
  presentation:'presentation',

  prepayment:'prepayment',
  uponpayment:'uponpayment',
  url: 'https://conruda.com/apiconruda',
  urlpdf: 'https://conruda.com/apiconrudapdf',
  urlexcel: 'https://conruda.com/apiexcel',
  //url: "https://colegio.equyman.pe/public_html/api/estudiante"
  //url:"http://localhost/upload.php"
 
  config: 'config',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
