import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-sale-process',
  templateUrl: './sale-process.page.html',
  styleUrls: ['./sale-process.page.scss'],
})
export class SaleProcessPage implements OnInit {

  constructor(
    private modalController:ModalController
  ) { }

  ngOnInit() {
  }


  onClick(){
    this.modalController.dismiss()
  }

}
