import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { DatabaseService } from "src/app/services/database.service";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { LoaderService } from "src/app/services/loader.service";
import { ValidatorsService } from "src/app/services/validators.service";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-credit-note-modal",
  templateUrl: "./credit-note-modal.page.html",
  styleUrls: ["./credit-note-modal.page.scss"],
})
export class CreditNoteModalPage implements OnInit ,OnDestroy {
  @Input("discountProd") discountProd ;
  public productRegisterForm: FormGroup;
  public stockDataForm: FormGroup;
  public creditNoteSaleDataForm:FormGroup;

  productName: any;
  sizesByProduct: any[];
  sizesByProductOriginal: any[];
  codSiz: any;
  productWithSize: any = [];
  cantidad: any;
  devolutionSizeButton: any = [];
  deleteSizeButton: any = [];
  changeSizeButton: any = [];
  updSizeButton = [];

  isVisibleRegister = false;
  isVisibleUpdate = false;

  price: any;
  numberProducts: any;
  idSale: any;
  images: any[] = [];
  idHeaderSale: any;
  checked: boolean = false;

  moment: any = moment;
  devueltos = 0;
  restantes = 0;
  getProductsWithStockSubs:Subscription;
  setSaleTableSubs:Subscription;
updSaleSubs:Subscription;
unsubscribeSignal: Subject<void> = new Subject();
  

  public errorMsm = {
    N_QUANTI: [
        { type: "required", message: "La cantidad es requerida." },
        { type: "min", message: "Solo valores positivos." },
        { type: "max", message: "Se sobrepaso los restantes." },
        { type: "pattern", message: "Solo valores enteros." },
       // { type: "max", message: "Máximo. 9 cifras." },
        { type: "min", message: "Numeros mayores a 0 porfavor" },
        { type: "nonInterger", message: "Solo enteros positivos." },
      ],
   };


  constructor(
    public modalController: ModalController,
    private _fb: FormBuilder,
    private _dataService: DatabaseService,
    private loaderService:LoaderService,
    private validatorsServic: ValidatorsService,
  ) {
    this.productRegisterForm = this._fb.group({
      //estos campos son para la tabla de producto
      C_DESPRO: ["", [Validators.required]],
      C_DESPRODET: [""],
      C_STAPRO: ["1"],
      IDCOMPANY: [""],
      IDDOMAINSIZE: [""],
      IDCATEGORY: [""],
      N_ORDEN: [""],
      isSizing: [false],

      C_BASE64IMG: [,],
      C_NAMIMG: [,],
      C_URLIMG: [""],

      upd_img: [false],
      id_resources: [],
    });

    this.stockDataForm = this._fb.group({
      ID: ["", [,]],
      IDPRODUCT: ["", [,]],
      C_CODSIZ: [""],
      C_DESSIZ: [""],
      N_COST: [, [,]],
      N_PRICEOFER: ["0", [,]],
      C_STATUS: ["1", [,]],
      IDCOMPANY: ["", [,]],
      N_CODBAR: ["", [,]],
      N_ORDEN: ["", [,]],
      N_TYPSAL: ["", [,]],
      C_CATALOG: [false, [,]],
      IDUSERCRE: ["", [,]],
      IDUSERUPD: ["", [,]],
      D_FECCRE: ["", [,]],
      D_FECUPD: ["", [,]],
      N_MINSTO: ["", [,]],

      C_FLAGOFFER: [ "",],




      //si tiene talla estos deben ser obligatorios:
      //estos campos son parte del tabla Stock ( si el producto no tiene talla seran required )
      N_PRICE: [, [Validators.required]],
      N_QUANTI: [, [Validators.required]],
    });

    
  }



  ngOnInit() {
    console.log("Est Modal, datos para deconteo: ", this.discountProd);
    this.idHeaderSale = this.discountProd.idHeaderSale;
    this.devueltos = this.discountProd.devueltos;
    this.restantes = this.discountProd.restantes
    this.numberProducts = this.discountProd.numberProducts
    console.log("this.numberProducts: " , this.numberProducts );
    console.log("this.devueltos " , this.devueltos );
    this.creditNoteSaleDataForm = this._fb.group({
          
      IDSALE: [ , ],
      C_DESPRO:[],


      IDPRODUCT: [ ,[Validators.required ,     ] ],
      C_CODSIZ: [ "",[,     ] ],
      N_QUANTI: [ ,[
        Validators.required , 
        //Validators.min(0.0000000001 ),
        Validators.max( this.numberProducts ) ,
        ValidatorsService.integerNumbers,
      ] ],
      N_COST: [ ,[Validators.required ,     ] ],
      N_PRICE: [ ,[Validators.required ,     ] ],
      C_TIPSAL: [ ,[Validators.required ,     ] ],
      C_DATSAL: [ ,[Validators.required ,     ] ],
      N_IDHEASAL:[ ,[Validators.required ,     ] ],
      C_STATUS: [ ,[Validators.required ,     ] ],
      C_STATUSPAYMENT: [ ,[Validators.required ,     ] ],
      IDCOMPANY: [ ,[Validators.required ,     ] ],
      N_SUBTOT: [ ,[Validators.required ,     ] ],

    })

     this.discountProd 

      console.log("iniciuo");
      

    this.idHeaderSale = this.discountProd.idHeaderSale;
    this.devueltos = this.discountProd.devueltos;
    if (this.discountProd.idCreditNoteSale) {
      this.isVisibleUpdate = true;
      this.isVisibleRegister = false;
    } else {
      this.isVisibleUpdate = false;
      this.isVisibleRegister = true;
    }

    this.getReminder(this.discountProd.idProduct);

    this.productRegisterForm.controls.isSizing.valueChanges.subscribe(
      (rpta) => {
        console.log("cambio en el chekkkeeeeed", rpta);
        this.checked = rpta;
        let containerList = document.getElementById("container-list");
        if (this.checked) {
          console.log("chek: ", this.checked);

        /*   if (this.cantidad) {
            containerList.style.height = "100%";
          } else {
            containerList.style.height = "91%";
          } */
        } else {
         // containerList.style.height = "100%";
        }
      }
    );


      this.creditNoteSaleDataForm.get("N_QUANTI").valueChanges.subscribe( (quanti)=>{
        console.log("cantidad: " , quanti );
        let subtotal = Number(this.creditNoteSaleDataForm.controls.N_PRICE.value)*quanti

        console.log("subttaolBD: " , subtotal );
        this.creditNoteSaleDataForm.controls.N_SUBTOT.setValue( subtotal )
          console.log("FormVAlid: ", this.creditNoteSaleDataForm );
        
      } )




      


    


  }


  ngOnDestroy(){
    this.unsubscribeSignal.next();
    this.unsubscribeSignal.complete();

  }









  closeModal() {
    this.modalController.dismiss();
  }

  async getReminder(id) {
    console.log("procede la busqueda(sell-product)!");
    let request = {
      ID: id, //idde producto
      N_IDHEASAL: this.idHeaderSale,
      IDCOMPANY: localStorage.getItem(environment.idcompany)
    };

    console.log("request getPrdWithSock: ", request);
    await this.loaderService.show()
   this.getProductsWithStockSubs =  this._dataService.getProductWithStock(request)
   .pipe( 
    takeUntil( this.unsubscribeSignal.asObservable() )
    ).
   subscribe(
      (rpta: any) => {
        this.loaderService.hide()
        console.log("getProductWithStockk: ", rpta);

        let id_resources = rpta.data.images[0] ? rpta.data.images[0].ID:''
    let c_urlimg =  rpta.data.images[0] ? rpta.data.images[0].C_URLIMG:'/assets/imagenes/no-product-img.png'
    console.log("idReosurce: ", id_resources );

        let newEntry = {
          name: "nombreImgen",
          path: c_urlimg,
          filePath: "filepath",
        };
        console.log("NewEntry: ", newEntry);

        this.images = [];
        this.images.push(newEntry);
        console.log("images: ", this.images);
        this.productRegisterForm.controls.id_resources.setValue(
          id_resources
        );

        if (rpta.status === "true") {
          //si tiene talla poner vista con el check activado;
          if (rpta.data.product[0].isSizing === "0") {
            //tiene el check desactivo (no talla) , por tanto solo tiene un stock relacionado a el
            //llenemos ambos formularios:

            this.productRegisterForm.controls.C_DESPRO.setValue(
              rpta.data.product[0].C_DESPRO
            );
            this.productRegisterForm.controls.C_DESPRODET.setValue(
              rpta.data.product[0].C_DESPRODET
            );
            this.productRegisterForm.controls.C_STAPRO.setValue(
              rpta.data.product[0].C_STAPRO
            );
            this.productRegisterForm.controls.IDCOMPANY.setValue(
              rpta.data.product[0].IDCOMPANY
            );
            this.productRegisterForm.controls.IDDOMAINSIZE.setValue(
              rpta.data.product[0].IDDOMAINSIZE
            );
            this.productRegisterForm.controls.IDCATEGORY.setValue(
              rpta.data.product[0].IDCATEGORY
            );
            this.productRegisterForm.controls.N_ORDEN.setValue(
              rpta.data.product[0].N_ORDEN
            );
            this.productRegisterForm.controls.isSizing.setValue(
              rpta.data.product[0].isSizing === "1" ? true : false
            );
            this.productRegisterForm.controls.C_URLIMG.setValue(
              c_urlimg
            );

            //llenado el formulario:

            this.stockDataForm.controls.IDPRODUCT.setValue(
              rpta.data.stocks[0].IDPRODUCT
            );
            this.stockDataForm.controls.C_CODSIZ.setValue(
              rpta.data.stocks[0].C_CODSIZ
            );
            this.stockDataForm.controls.C_DESSIZ.setValue(
              rpta.data.stocks[0].C_DESSIZ
            );
            this.stockDataForm.controls.N_COST.setValue(
              rpta.data.stocks[0].N_COST
            );
            this.stockDataForm.controls.N_PRICEOFER.setValue(
              rpta.data.stocks[0].N_PRICEOFER
            );
            this.stockDataForm.controls.C_STATUS.setValue(
              rpta.data.stocks[0].C_STATUS
            );
            this.stockDataForm.controls.IDCOMPANY.setValue(
              rpta.data.stocks[0].IDCOMPANY
            );
            this.stockDataForm.controls.N_CODBAR.setValue(
              rpta.data.stocks[0].N_CODBAR
            );
            this.stockDataForm.controls.N_ORDEN.setValue(
              rpta.data.stocks[0].N_ORDEN
            );
            this.stockDataForm.controls.N_TYPSAL.setValue(
              rpta.data.stocks[0].N_TYPSAL
            );
            this.stockDataForm.controls.C_CATALOG.setValue(
              rpta.data.stocks[0].C_CATALOG
            );
            this.stockDataForm.controls.IDUSERCRE.setValue(
              rpta.data.stocks[0].IDUSERCRE
            );
            this.stockDataForm.controls.IDUSERUPD.setValue(
              rpta.data.stocks[0].IDUSERUPD
            );
            this.stockDataForm.controls.D_FECCRE.setValue(
              rpta.data.stocks[0].D_FECCRE
            );
            this.stockDataForm.controls.D_FECUPD.setValue(
              rpta.data.stocks[0].D_FECUPD
            );
            this.stockDataForm.controls.N_PRICE.setValue(
              rpta.data.stocks[0].N_PRICE
            );
            this.stockDataForm.controls.N_QUANTI.setValue(
              rpta.data.stocks[0].N_QUANTI
            );
            this.stockDataForm.controls. C_FLAGOFFER.setValue(
              rpta.data.stocks[0]. C_FLAGOFFER
            );
            this.stockDataForm.controls.N_MINSTO.setValue(
              rpta.data.stocks[0].N_MINSTO
            );
            //---------------------- nuevo form( borra el otro) -------------------------------------//

            const price =  this.stockDataForm.controls.C_FLAGOFFER.value == '1'? this.stockDataForm.controls.N_PRICEOFER.value : this.stockDataForm.controls.N_PRICE.value

     
          this.creditNoteSaleDataForm.controls.IDSALE.setValue( this.discountProd.idCreditNoteSale );
          this.creditNoteSaleDataForm.controls.IDPRODUCT.setValue( rpta.data.stocks[0].IDPRODUCT );
          this.creditNoteSaleDataForm.controls.C_CODSIZ.setValue( null );

          this.creditNoteSaleDataForm.controls.N_COST.setValue( rpta.data.stocks[0].N_COST );

          this.creditNoteSaleDataForm.controls.N_PRICE.setValue( price );

          this.creditNoteSaleDataForm.controls.C_DESPRO.setValue( rpta.data.product[0].C_DESPRO );

          this.creditNoteSaleDataForm.controls.C_TIPSAL.setValue( "-1" );
          this.creditNoteSaleDataForm.controls. C_DATSAL.setValue( moment().format("YYYY-MM-DD") );
          this.creditNoteSaleDataForm.controls.C_STATUS.setValue( "1" );
          this.creditNoteSaleDataForm.controls.C_STATUSPAYMENT.setValue( "0" );

          this.creditNoteSaleDataForm.controls.N_IDHEASAL.setValue( this.discountProd.idCreditNote );
          this.creditNoteSaleDataForm.controls.IDCOMPANY.setValue( rpta.data.stocks[0].IDCOMPANY );

            //-------------------------------------------------------------//
            this.productName = this.productRegisterForm.controls.C_DESPRO.value;

            this.price = this.stockDataForm.controls.N_PRICE.value;
            this.numberProducts = rpta.data.stocks[0].cantidad;
            this.idSale = rpta.data.stocks[0].IDSALE;
            //como cambio el valor de boton de guardar a actualizarB

            // this.optionsModal( '',false,true,rpta.data.stocks[0].ID , false ); (se lanza :( )
          } else if (rpta.data.product[0].isSizing === "1") {
            //el check activo
            //llenemos ambos formularios:

            this.productRegisterForm.controls.C_DESPRO.setValue(
              rpta.data.product[0].C_DESPRO
            );
            this.productRegisterForm.controls.C_DESPRODET.setValue(
              rpta.data.product[0].C_DESPRODET
            );
            this.productRegisterForm.controls.C_STAPRO.setValue(
              rpta.data.product[0].C_STAPRO
            );
            this.productRegisterForm.controls.IDCOMPANY.setValue(
              rpta.data.product[0].IDCOMPANY
            );
            this.productRegisterForm.controls.IDDOMAINSIZE.setValue(
              rpta.data.product[0].IDDOMAINSIZE
            );
            this.productRegisterForm.controls.IDCATEGORY.setValue(
              rpta.data.product[0].IDCATEGORY
            );
            this.productRegisterForm.controls.N_ORDEN.setValue(
              rpta.data.product[0].N_ORDEN
            );
            this.productRegisterForm.controls.isSizing.setValue(
              rpta.data.product[0].isSizing === "1" ? true : false
            );
            this.productRegisterForm.controls.C_URLIMG.setValue(
              c_urlimg
            );

            this.productName = this.productRegisterForm.controls.C_DESPRO.value;
            this.sizesByProduct = [];

            //generar las minitablas.

            this.sizesByProduct = rpta.data.stocks.filter(
              (e) => e.C_CODSIZ == this.discountProd.codSiz
            );
            console.log("SizezByProduct:: ", this.sizesByProduct);
            this.numberProducts = this.sizesByProduct[0].cantidad;
            this.devolutionSizeButton[this.sizesByProduct[0].ID] = true;

            //-------------------- crediNotSale Data fom ---------------------------------//
              const price = this.sizesByProduct[0].C_FLAGOFFER == '1'? this.sizesByProduct[0].N_PRICEOFER : this.sizesByProduct[0].N_PRICE;

            this.creditNoteSaleDataForm.controls.IDSALE.setValue( this.discountProd.idCreditNoteSale );
            this.creditNoteSaleDataForm.controls.IDPRODUCT.setValue( this.sizesByProduct[0].IDPRODUCT );
            this.creditNoteSaleDataForm.controls.C_CODSIZ.setValue( this.sizesByProduct[0].C_CODSIZ );
            this.creditNoteSaleDataForm.controls.N_COST.setValue( this.sizesByProduct[0].N_COST );
            this.creditNoteSaleDataForm.controls.N_PRICE.setValue( price );
            this.creditNoteSaleDataForm.controls.N_IDHEASAL.setValue( this.discountProd.idCreditNote );
            this.creditNoteSaleDataForm.controls.IDCOMPANY.setValue( this.sizesByProduct[0].IDCOMPANY );
                 
          this.creditNoteSaleDataForm.controls.C_DESPRO.setValue( rpta.data.product[0].C_DESPRO );
          this.creditNoteSaleDataForm.controls.C_TIPSAL.setValue( "-1" );
          this.creditNoteSaleDataForm.controls. C_DATSAL.setValue( moment().format("YYYY-MM-DD") );
          this.creditNoteSaleDataForm.controls.C_STATUS.setValue( "1" );
          this.creditNoteSaleDataForm.controls.C_STATUSPAYMENT.setValue( "0" );
          //-----------------------------------------------------------------------------//

          console.log("sizesByProduct: " , this.sizesByProduct );
          


          } else {
            console.log("no tiene seteaso el isSing correctamente.");
          }
        } else {
          console.log("error en la petiocn de stock-product");
          //
        }
      },
      (err) => {
        console.log("error: ", err);
        this.loaderService.hide();
      }
    );
  }
  selected() {
    throw new Error("Method not implemented.");
  }
  sizesSelected(arg0: string, sizesSelected: any) {
    throw new Error("Method not implemented.");
  }

  numberProductsToReturn = null;
  productWithSizeToReturn = [];
  devolver(stock) {

    console.log("Data Form a mandar (devolver): ", this.creditNoteSaleDataForm  );
    if(this.creditNoteSaleDataForm.valid){

      let request = this.creditNoteSaleDataForm.value;

      console.log("Request a descontar (deviolver): ", request);
      //setear el sale
      this.setSaleTableSubs = this._dataService.setSaleTable(request)
      .pipe( 
        takeUntil( this.unsubscribeSignal.asObservable() )
        )
      .subscribe((sale) => {
        console.log("Rpta sale (size): ", sale);
        this.modalController.dismiss(sale);
        
      });
    }else{
      this.creditNoteSaleDataForm.controls.N_QUANTI.setValue(null);
      this.creditNoteSaleDataForm.controls.N_QUANTI.markAsTouched(); 
    }

   /*  if (stock) {
      let request = {
        //
        IDCREDITNOTESALE: this.discountProd.idCreditNoteSale,
        IDPRODUCT: stock.IDPRODUCT,
        C_CODSIZ: stock.C_CODSIZ,
        N_QUANTI: this.productWithSizeToReturn[stock.ID], //cantidad que se esta devolviendo
        N_COST: stock.N_COST,
        N_PRICE: stock.N_PRICE,
        C_TIPSAL: "-1",
        C_DATSAL: moment().format("YYYY-MM-DD"),
        N_IDHEASAL: this.discountProd.idCreditNote,
        C_STATUS: "1",
        C_STATUSPAYMENT: "0",
        IDCOMPANY: stock.IDCOMPANY,
        N_SUBTOT: +this.productWithSizeToReturn[stock.ID] * +stock.N_PRICE,
      };

      console.log("Request a descontar: ", request);
      //setear el sale
      this._dataService.setSaleTable(request).subscribe((sale) => {
        console.log("Request sale (size): ", sale);
        this.modalController.dismiss(sale);
      });
    } else {
      console.log("StockDataForm a devolver (no size) ", this.stockDataForm);
      console.log("ProductDataForm ", this.productRegisterForm);
      let request = {
        //
        IDPRODUCT: this.stockDataForm.controls.IDPRODUCT.value,
        C_CODSIZ: null,
        N_QUANTI: this.numberProductsToReturn,
        N_COST: this.stockDataForm.controls.N_COST.value,
        N_PRICE: this.stockDataForm.controls.N_PRICE.value,
        C_TIPSAL: "-1",
        C_DATSAL: moment().format("YYYY-MM-DD"),
        N_IDHEASAL: this.discountProd.idCreditNote,
        C_STATUS: "1",
        C_STATUSPAYMENT: "0",
        IDCOMPANY: this.stockDataForm.controls.IDCOMPANY.value,
        N_SUBTOT:
          +this.numberProductsToReturn *
          +this.stockDataForm.controls.N_PRICE.value,
      };
      console.log("Requat sin Size (No size): ", request);

      this._dataService.setSaleTable(request).subscribe((sale) => {
        console.log("rpta sale (No size)__: ", sale);
        this.modalController.dismiss(sale);
      });
    } */


  }

  updDevolver(stock) {

    console.log("Data Form a mandar (devolver): ", this.creditNoteSaleDataForm  );
    if(this.creditNoteSaleDataForm.valid){

      let request = this.creditNoteSaleDataForm.value;

      console.log("request Actualizar: ", request);

     this.updSaleSubs = this._dataService.updSale(request)
     .pipe( 
      takeUntil( this.unsubscribeSignal.asObservable() )
      )
     .subscribe((updSale) => {
        console.log("rpta updSale (size): ", updSale);

        this.modalController.dismiss(updSale);
      });
    }else{
      //this.creditNoteSaleDataForm.controls.N_QUANTI.setValue(null);
      this.creditNoteSaleDataForm.controls.N_QUANTI.markAsTouched(); 
    }
 /*    if (stock) {
      let request = {
        IDSALE: this.discountProd.idCreditNoteSale,
        N_QUANTI: Number(this.productWithSizeToReturn[stock.ID]),

        C_STATUS: "1",
        N_SUBTOT:
          Number(this.productWithSizeToReturn[stock.ID]) *
          Number(stock.N_PRICE),
      };

      console.log("request Actualizar: ", request);

      this._dataService.updSale(request).subscribe((updSale) => {
        console.log("rpta updSale (size): ", updSale);

        this.modalController.dismiss(updSale);
      });
    } else {
      let request = {
        IDSALE: this.discountProd.idCreditNoteSale,
        N_QUANTI: Number(this.numberProductsToReturn),

        C_STATUS: "1",
        N_SUBTOT:
          Number(this.numberProductsToReturn) *
          Number(this.stockDataForm.controls.N_PRICE.value),
      };

      this._dataService.updSale(request).subscribe((updSale) => {
        console.log("rpta updSale(no size): ", updSale);

        this.modalController.dismiss(updSale);
      });
    } */



  }
}
