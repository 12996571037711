import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
 moment:any = moment.locale('es');

  transform(value: string): any {

    console.log("pipe date: ", value);
    
      let dayname = moment(value).format('dddd');
      let day = moment(value).format('DD');
      let month = moment(value).format('MMM');
      let year = moment(value).format('YYYY');

      let dateString = `${dayname}, ${day} del ${month} del ${year}`

    return dateString;
  }

}
