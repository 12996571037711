
import { InterpageService } from "../services/interpage.service";
import { Injectable } from "@angular/core";
import { Resolve , ActivatedRouteSnapshot } from "@angular/router";

@Injectable({
    providedIn:"root"
})
export class CartResolver implements Resolve<any>{
    constructor(
        public interPageService : InterpageService
    ){}

    resolve( route:ActivatedRouteSnapshot ){

        /* let id = route.paramMap.get()
        return this.http.post(`${URL}/getHeaderSale`, data , { headers: this.headers })
        .pipe(
          timeout(this.nTimeout),
          retry(this.nRetry),
          catchError(this.handleError)
        ); */
        return this.interPageService.getIdHeaderSale();

    }
}