import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSale'
})
export class FilterSalePipe implements PipeTransform {

  transform(arreglo: any[] , text: any): any {
    console.log("Sale pipe arr: ", arreglo );
    console.log("Sale pipe text: ", text );
    

    if(text === ''){
      return arreglo;
    }

    text = text.toLowerCase();

    return arreglo.filter( item =>{
      return item.C_DESPRO.toLowerCase().includes( text );
    })



  }

}
