import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InterpageService } from 'src/app/services/interpage.service';

@Component({
  selector: 'app-multi-step',
  templateUrl: './multi-step.component.html',
  styleUrls: ['./multi-step.component.scss'],
})
export class MultiStepComponent implements OnInit, OnDestroy {
  stepSubscription: Subscription;
  public slides: string[];
  public stepsWithError: Array<string> = [];
  public slidesOpts = {
    allowTouchMove: false,
    autoHeight: true,
  };
  unsubscribeSignal: Subject<void> = new Subject();
  @Input('step') step: Observable<any>;
  public currentSlide: string;
  public isBeginning: boolean = true;
  public isEnd: boolean = false;
  constructor(
    private router: Router,
    private interPageService: InterpageService,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    this.buildSlides();

    this.stepSubscription = this.step.pipe( 
      takeUntil( this.unsubscribeSignal.asObservable() )
     )
    
    .subscribe((step) => {
      console.log('Llego esto a multicompoent: ', step);
      this.currentSlide = step.route && step.route;

      let errors = [];

      let coolObject = {
        IDTYPEPAYMENT: 'Medio de pago',
        IDTYPESUBPAYMENT: 'Medio de pago',
        C_STATUSPAYMENT: 'Medio de pago',
        C_TYPSAL: 'Entrega',
        validateSale: 'Producto',
      };

      step.stepErros.forEach((element) => {
        console.log('element: ', element);
        errors.push(coolObject[element]);
      });

      this.stepsWithError = errors;
      console.log('this.stepWithErro: ', this.stepsWithError);
    });
  }

  buildSlides() {
    const slides = [
      'Cliente',
      'Producto',
      'Entrega',
      'Medio de pago',
      'Desct.',
    ];
    this.currentSlide = slides[0];
    this.slides = slides;
  }

  ngOnDestroy() {
    this.stepSubscription.unsubscribe();
    this.unsubscribeSignal.next();
    this.unsubscribeSignal.complete();
  }

  verifyWaves(step): boolean {
    let show = this.stepsWithError.includes(step);
    //console.log("show");
    if (show) {
      return true;
    }
    return false;
  }

  activar: boolean = false;
  goTo(slide) {
    let idHeaderSale = this.interPageService.getIdHeaderSale(); //idHeaderSale
    console.log('IDHEADERSAle nultiSttep: ', idHeaderSale);
    /*  if( idHeaderSale === '' ){

      this.presentToast("Presione agregar producto");
      //return;
    }
 */

    console.log('Vamos a: ', slide);

    switch (slide) {
      case 'Cliente':
        console.log('client');

        this.router.navigate(['clients']);

        break;
      case 'Producto':
        
        this.router.navigate(['/sell/sell-modalcito']);

        break;
      case 'Entrega':
        if (idHeaderSale == '') {
          this.presentToast("Presione:'Agregar un producto'");
          this.stepsWithError.push('Producto')
          break;
        } else {
          this.router.navigate(['select-typesal']);

          break;
        }

      case 'Medio de pago':
        if (idHeaderSale == '') {
          this.presentToast("Presione:'Agregar un producto'");
          this.stepsWithError.push('Producto')
          break;
        } else {
          this.router.navigate(['settings/payment-method']);

          break;
        }

      case 'Desct.':
        if (idHeaderSale == '') {
          this.presentToast("Presione:'Agregar un producto'");
          this.stepsWithError.push('Producto')
          break;
        } else {
          this.router.navigate(['cart']);

          break;
        }

      default:
        console.log('Ninguna de las anteriores');

        break;
    }
  }

  async presentToast(msm: string) {
    const toast = await this.toastController.create({
      message: msm,
      position: 'middle',
      duration: 2000,
      color: 'success',
    });
    toast.present();
  }
}
