import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterHeader'
})
export class FilterHeaderPipe implements PipeTransform {

  transform(arreglo: any[] , text: any): any {
    
    console.log("header pipe arr: ", arreglo );
    console.log("header pipe text: ", text );
    

    if(text === ''){
      return arreglo;
    }
    text = text.toLowerCase();

    return arreglo.filter(  item =>{

      if( item.C_NAMCLI ){
        return item.C_NAMCLI.toLowerCase().includes( text )
      }
      
    });

  }

}
