import { Component, Input, OnInit, Output , EventEmitter, ChangeDetectorRef} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})
export class ClientListComponent implements OnInit {

  @Input() customers$: Observable<any> ;
  @Input() searchText = '';
  @Output() newItemEvent = new EventEmitter<any>();
  subscription: Subscription;
  customers = [];
  page = '';
  idDefaultCustomer = '';
  balanceStatus = true;
  constructor(
    private cdr:ChangeDetectorRef
  ) {
    this.idDefaultCustomer = localStorage.getItem(environment.iddefaultcustomer);
   }

  ngOnInit() {

    console.log("la lista de customers: ", this.customers$ );
    this.subscription = this.customers$.subscribe( ( customers:any )=>{

      this.customers = customers.customers;
      this.page = customers.page;
      console.log("this.customer ClientList? ", this.customers );
      this.cdr.detectChanges();

    } );
    
  }




  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  redirectToSell( customer  ){
    

    console.log("redirectt!!");
    
    const customerData = {
      C_NAMCUS:customer.C_NAMCUS,
      C_LASNAMCUS:customer.C_LASNAMCUS,
      ID:customer.ID
    }
    this.newItemEvent.emit(customerData)

  }


}
