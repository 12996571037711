import { Pipe, PipeTransform } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  constructor( private toastController:ToastController ){

  }

  //let element of 1.-{products | filter}:  2.-{searchText}:  3.-{'C_DESPRO'}  "
  transform(arreglo: any[], text: string , column:string , component:string  ): any[] {


    console.log('Estamos en pipes Productos: ',arreglo);
    console.log('Column: ', column );
  /*   console.log('este es el pedazo texto: ',text);
    console.log('Esta es la colmna: ', column );
    console.log( );
       */
       if( component === 'pick' ){
        return arreglo
       }


    if( column == null ){
      return arreglo

    }  
    
    if(text === ''){
      return arreglo;
    }

    text = text.toLowerCase();



   const searchedProducts = arreglo.filter( item =>{

    if( item[column] == null ){
      return true;
    }




      let filterText = item[column]+item['product'][0]['C_DESPRO'];
      //console.log("textoJunto;: ", filterText );
      

      return filterText.toLowerCase().includes( text );
    } )

    console.log("productos filtrados: ", searchedProducts );
    

    if( searchedProducts.length == 0 ){

      this.toastController.getTop().then( ( top )=>{
        if( !top ){
          this.presentToast("Sin resultados, cargue mas en el boton inferior o presione el botón Buscar .");
        }
        

      } )
     
      
    }

    return searchedProducts;


  }





  async presentToast(msm: string) {
    const toast = await this.toastController.create({
      message: msm,
      position: 'middle',
      duration: 2000,
      color: 'success',
    });
    toast.present();


  

  }

}
