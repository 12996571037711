import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { from, Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PrePaymentGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<UrlTree> {
    let config = JSON.parse(  localStorage.getItem(environment.config) );
    console.log( "DataStoreGuard: ", config);

    //console.log("pay-metod");
    

    if ( config  && config.detSettings.delivery === false  ) {
     console.log("Configuremos prepayment");
      const tree: UrlTree = this.router.parseUrl("/prepayment");
      return tree;

      //console.log("prepaymentG: ", rpta );
    
      
    } else {
    return true;
    }
  }
}
