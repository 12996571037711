import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  URLAPP : string = '';
  constructor() { }
   
 async setURLApp(urlapp){
    console.log("setea: ",urlapp)
    //this.storage.set('URL_APP_TMP',urlapp);
   /*  await Storage.set({
      key: 'URL_APP_TMP',
      value: urlapp,
    }); */
    this.URLAPP=urlapp
  }

   getURLApp(){
    //this.storage.get('URL_APP_TMP').then((data)=>{this.URLAPP=data;})
   /*  const url = await Storage.get({ key: 'URL_APP_TMP' }).then( (url)=>{
      return url
    } )
    console.log("value2: ", url );
    
    return url.value; */
    console.log("thisurlp: ", this.URLAPP );
    
    return this.URLAPP
  }
  async clearURLApp(){
    //this.storage.set('URL_APP_TMP',null);
    await Storage.set({
      key: 'URL_APP_TMP',
      value: null,
    });
  }
}
