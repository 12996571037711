import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { from, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map, switchMap } from 'rxjs/operators';
import { AlertController, Platform } from '@ionic/angular';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DataStoreGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private platform:Platform,
   // private Storage: Storage,
    public alertController: AlertController
  ){
    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<UrlTree> {
    let config = JSON.parse(  localStorage.getItem(environment.config) );
    console.log( "DataStoreGuard: ", config);
    

    if ( config && config.detSettings.confstore === true ) {

      return true;
      
    } else {
     // this.presentAlert();
     
      
      const tree: UrlTree = this.router.parseUrl("/configurations");
      return tree;
    }
  }


  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Configuraciones iniciales.',
      message: `Bienvenid@ ${localStorage.getItem(environment.nameuser) }, ahora realizaremos algunas configuraciones iniciales. <ion-icon name="color-wand"></ion-icon> `,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
}
