import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'descriptionExpense'
})
export class DescriptionExpensePipe implements PipeTransform {

  transform( dataArray :any ,  filterx: any  ): any {

    if( !filterx || !Array.isArray(dataArray) ){
      return dataArray;
    }

    if( filterx && Array.isArray(dataArray)  ){

      let filterKeyss = Object.keys( filterx );
      if (false) {
        
      } else {

        let row = dataArray.filter( (item)=>{

          return filterKeyss.some( ( keyname )=>{

            return new RegExp( filterx[keyname], 'gi' ).test(
              item[keyname]
            )


          } )
          


        } )

        return row

      }

    }


  }
}
