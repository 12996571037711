import { Pipe, PipeTransform } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Pipe({
  name: 'myExpencesPipe',
})
export class MyExpencesPipe implements PipeTransform {
  selectExpenses = [];
  constructor(private toastController: ToastController) {}

  transform(items: any, filterx: any)  {
    console.log('Orders Pipe: ', items);

    if (!filterx || !Array.isArray(items)) {
      return items;
    }

    if (filterx && Array.isArray(items)) {
      let filterKeyss = Object.keys(filterx);
      if (false) {
      } else {
     

        let row = items.filter((item) => {
          
          let expences = item['expences'].filter((expense) => {
            return filterKeyss.some((keyname) => {
              return new RegExp(filterx[keyname], 'gi').test(expense[keyname]);
            });
          });

          console.log('expenses: ', expences);

          if (expences.length > 0) {
            let selectedItem = {
              ...item,
              expences: expences,
            };
            console.log('armani: ', selectedItem);
            this.selectExpenses = selectedItem;
            return selectedItem;
          }
        });

     
        let filter = row.filter((e) => e != undefined);

        /*     if( filter.length == 0 ){

          this.toastController.getTop().then( ( top )=>{
            if( !top ){
              this.presentToast("Sin resultados, cargue mas en el boton inferior.");
            }
            
    
          } )
         
          
        } */

        return filter;
      }
    }
  }

  async presentToast(msm: string) {
    const toast = await this.toastController.create({
      message: msm,
      position: 'middle',
      duration: 2000,
      color: 'success',
    });
    toast.present();
  }
}
