import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  transform(value: any, args:number ): any {
  

    let flotante = parseFloat(value);

    console.log("currcpipe: " , flotante.toFixed( args ) );

    
    return flotante.toFixed( args )


  }

}
