import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import * as moment from "moment";
import { DatabaseService } from "src/app/services/database.service";
import {
  ActionSheetController,
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { environment } from "src/environments/environment";
import { map, takeUntil } from "rxjs/operators";
import { InterpageService } from "src/app/services/interpage.service";
import { Router } from "@angular/router";
import { ValidatorsService } from "src/app/services/validators.service";
import { Location } from "@angular/common";
import { element } from "protractor";
import { LoaderService } from "src/app/services/loader.service";
import { forkJoin } from 'rxjs';
import { patternName } from '../../patterns/patterns.const'
import { patternDirection } from '../../patterns/patterns.const'
import { SaleProcessPage } from "src/app/sale-process/sale-process.page";


@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.scss"],
})
export class OrderDetailComponent implements OnInit , OnDestroy {
  @Input() idheadersale: string;
  //@Input() order:string;
  @Input() idCustomer: string;
  @Input() idTypepayment: string;
  eventsSubject: Subject<void> = new Subject<void>();

  statussale = "";
  headerSaleForm: FormGroup;
  AddressForm: FormGroup;
  selectTabs: string = "DETALLES";

  public requests:any = [];
  currentPosition;
  height;
  minimumThreshold;
  startThreshold;
  startPosition;
  productsForDiscountStock = [];
  deliverymount = 0;
  showSkeleton:boolean = true;
  salesByHeaderSale = [];
  totalPay = 0;
  moment: any = moment;
  dateSale;
  companyAddress = "";
  termOfCompany = "";
  payMethodCompany: any;
  userName = "";
  idUnique = "";
  customer;
  subpayment;
  patternName = patternName;
  patternDirection = patternDirection;

  clickToErrorHttpSubs:Subscription;
forkJoin:Subscription;
updHeaderSalesSubs3:Subscription;
updHeaderSalesubs2:Subscription;
updHeaderSalesubs:Subscription;
unsubscribeSignal: Subject<void> = new Subject();

  constructor(
    private databaseService: DatabaseService,
    public actionSheetController: ActionSheetController,
    private modalCtrl: ModalController,
    private _fb: FormBuilder,
    public toastController: ToastController,
    public alertController: AlertController,
    public renderer: Renderer2,
    private interPageService: InterpageService,
    private router: Router,
    private validatorsServic: ValidatorsService,
    private location:Location,
    private loaderService:LoaderService,
 
    
  ) {}
  public errorMsm = {
    C_ADDRESS:[ { type: "required", message: "El nombre es requerido." },
    { type: "pattern", message: "Caractéres no permitidos." },
    { type: "maxlength", message: "Máximo 100 caracteres porfavor." }, ],

    C_REFERENCE:[ { type: "required", message: "El nombre es requerido." },
    { type: "pattern", message: "Caractéres no permitidos." },
    { type: "maxlength", message: "Máximo 100 caracteres porfavor." }, ],



    C_TERMCOMP: [
      { type: "required", message: "Requerido" },
      { type: "nonInterger", message: "Solo enteros positivos." },
      { type: "quantity", message: "Máximo. 9 cifras" },
      { type: "nonZero", message: "no valido" },
    ],
  }

  get C_ADDRESS (){
    return this.headerSaleForm.get('C_ADDRESS')
  }
  get C_REFERENCE (){
    return this.headerSaleForm.get('C_REFERENCE')
  }
  get C_TERMCOMP (){
    return this.headerSaleForm.get('C_TERMCOMP')
  }

  get statusColor (){
    return this.headerSaleForm.get('statusColor')
  }

  get statusIcon (){
    return this.headerSaleForm.get('statusIcon')
  }

  ngOnInit() {
    this.showSkeleton = true;
   this.clickToErrorHttpSubs =  this.databaseService.getClickToErrorHttp().subscribe( ()=>{
      console.log("main page error!!!");
      //this.isWait = false;

      this.loaderService.hide();
    } )

    this.initializeForms();

    this.close();
        this.closeAddress();
    this.closeTerm();
    this.closeREFERENCE();
    console.log("this.idhedsale: ", this.idheadersale);


    //this.getDetails();//
    this.getParameters();
    this.loadHeaderSale();
    this.loadSalesByHeaderId();

    this.forkAllRequest();



  }



  async openHelp(){
    const modal = await this.modalCtrl.create({
      component: SaleProcessPage,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  ngOnDestroy(){
    this.clickToErrorHttpSubs.unsubscribe();

    this.unsubscribeSignal.next();
    this.unsubscribeSignal.complete();

  }

  deliveryMountString(){
    return this.deliverymount;
  }

  async forkAllRequest(){
   
    console.log("FORK JOIN");
    await this.loaderService.show();
  this.forkJoin =  forkJoin(this.requests)
  .pipe( 
    takeUntil( this.unsubscribeSignal.asObservable() )
    )
  .subscribe( (data:any)=>{
    this.loaderService.hide();
      console.log("forkJoin: ", data );
      //this.setDetails();
      console.log("setPArameters");
      
      this.setParameters( data[0] );
      console.log("setHeaderSale");
      
      this.setHeaderSale( data[1] );
      this.setSalesByHeaderId( data[2] );
      this.loaderService.hide();

    }, err=>{  this.loaderService.hide(); } )
    

  }

  setSalesByHeaderId(sales){
    this.showSkeleton = false;
    //this.salesByHeaderSale = sales.data;
    console.log("prods to sale: ",sales);
    

    let sum = 0;
    let cost = 0;
    
    this.productsForDiscountStock = [];
    const products = []
    sales.data.forEach((product) => {
      console.log("Product to sum(orDeta): ", product);
      //----------------------------------------------------//
      if (product.isSizing == 0) {
        let discount = {
          cantidad: product.cantidad,
          idProduct: product.IDPRODUCT,
          isSizing: product.isSizing,
        };
        this.productsForDiscountStock.push(discount);
      } else {
        let discountWithSize = {
          cantidad: product.cantidad,
          codSiz: product.C_CODSIZ,
          idProduct: product.IDPRODUCT,
          isSizing: product.isSizing,
        };
        this.productsForDiscountStock.push(discountWithSize);
      }

      
      if( product.C_CODSIZ == null ){
        console.log("nullooo");
        let prod = {
          ...product,
          C_CODSIZ:''
        }
        products.push(prod);
        
      } else{
        products.push(product);
      }

     
      console.log("salesByHeaderSale: ", this.salesByHeaderSale );
      
      //----------------------------------------------------//
      sum = sum + +product.N_SUBTOT;
      cost = cost + +(+product.cantidad * +product.N_COST);
    });
 this.salesByHeaderSale = products;

  }

  setParameters( rpta ){
    console.log("rpta Lista paramttros: ", rpta);
    this.listParameters = rpta;
  }

  setHeaderSale( headersale:any ){
    console.log("HeaderSale::(detail) ", headersale);
    this.dateSale = moment(headersale.data[0].D_DATSAL).format(
      "DD/MM/YYYY [a las] HH:mm"
    );

    this.headerSaleForm.controls.C_STATUS.setValue(
      headersale.data[0].C_STATUS
    );

    this.headerSaleForm.controls.C_STATUSPAYMENT.setValue(
      headersale.data[0].C_STATUSPAYMENT
    );

    this.headerSaleForm.controls.D_DATSAL.setValue(
      headersale.data[0].D_DATSAL
    );
    this.headerSaleForm.controls.IDHEADERSALE.setValue(headersale.data[0].ID);
    this.headerSaleForm.controls.N_TOTDIS.setValue(
      headersale.data[0].N_TOTDIS
    );
    this.headerSaleForm.controls.IDCOMPANY.setValue(
      headersale.data[0].IDCOMPANY
    );
    this.headerSaleForm.controls.C_OBSERV.setValue(
      headersale.data[0].C_OBSERV
    );
    this.headerSaleForm.controls.N_TOTPAY.setValue(
      headersale.data[0].N_TOTPAY
    );
    this.headerSaleForm.controls.N_DELIVERYMOUNT.setValue(
      headersale.data[0].N_DELIVERYMOUNT
    );

    
    this.headerSaleForm.controls.IDCUSTOMER.setValue(
      headersale.data[0].IDCUSTOMER
    );


    this.headerSaleForm.controls.C_ADDRESS.setValue(
      headersale.data[0].C_ADDRESS
    );

    this.headerSaleForm.controls.C_TERMCOMP.setValue(
      headersale.data[0].C_TERMCOMP
    );


    this.headerSaleForm.controls.IDTYPEPAYMENT.setValue(
      headersale.data[0].IDTYPEPAYMENT
    );
    this.headerSaleForm.controls.IDTYPESUBPAYMENT.setValue(
      headersale.data[0].IDTYPESUBPAYMENT
    );
    this.headerSaleForm.controls.c_statussale.setValue(
      headersale.data[0].c_statussale
    );
    this.headerSaleForm.controls.C_NUMDOC.setValue(
      headersale.data[0].C_NUMDOC
    );
    this.headerSaleForm.controls.N_TOTSAL.setValue(
      headersale.data[0].N_TOTSAL
    );



    this.headerSaleForm.controls.C_NAMCLI.setValue(
      headersale.data[0].C_NAMCLI
    );


    this.headerSaleForm.controls.C_ADDREF.setValue(
      headersale.data[0].C_ADDREF
    );

    this.headerSaleForm.controls.C_REFERENCE.setValue(
      headersale.data[0].C_ADDREF
    );



    this.headerSaleForm.controls.C_EMACUS.setValue(
      headersale.data[0].C_EMACUS
    );
    this.headerSaleForm.controls.C_NUMPHO.setValue(
      headersale.data[0].C_NUMPHO
    );
    this.headerSaleForm.controls.C_ADDCUS.setValue(
      headersale.data[0].C_ADDCUS
    );




    this.headerSaleForm.controls.C_PHOCLI.setValue(
      headersale.data[0].C_PHOCLI
    );
    this.headerSaleForm.controls.N_TOTCAS.setValue(
      headersale.data[0].N_TOTCAS
    );
    this.headerSaleForm.controls.C_TYPSAL.setValue(
      headersale.data[0].C_TYPSAL
    );
    this.headerSaleForm.controls.N_DISPRO.setValue(
      headersale.data[0].N_DISPRO
    );
    this.headerSaleForm.controls.N_TOTCOS.setValue(
      headersale.data[0].N_TOTCOS
    );
    this.headerSaleForm.controls.IDPOINTSALES.setValue(
      headersale.data[0].IDPOINTSALES
    );
    this.headerSaleForm.controls.C_STATUSTAX.setValue(
      headersale.data[0].C_STATUSTAX
    );
    this.headerSaleForm.controls.C_TYPDOC.setValue(
      headersale.data[0].C_TYPDOC
    );
    this.headerSaleForm.controls.C_NUMBERCPE.setValue(
      headersale.data[0].C_NUMBERCPE
    );
    this.headerSaleForm.controls.IDREFTAX.setValue(
      headersale.data[0].IDREFTAX
    );

    this.headerSaleForm.controls.HASHTAX.setValue(headersale.data[0].HASHTAX);
    this.headerSaleForm.controls.MESSAGETAX.setValue(
      headersale.data[0].MESSAGETAX
    );

    this.headerSaleForm.controls.statuspayment.setValue(headersale.data[0].statuspayment);
    this.headerSaleForm.controls.statusorder.setValue(headersale.data[0].statusorder);

    this.headerSaleForm.controls.salemethod.setValue(headersale.data[0].salemethod);
    this.headerSaleForm.controls.paymethod.setValue(headersale.data[0].paymethod);


    this.headerSaleForm.controls.C_DESSUBPAY.setValue(headersale.data[0].C_DESSUBPAY);
    this.headerSaleForm.controls.C_NAMACO.setValue(headersale.data[0].C_NAMACO);


    this.headerSaleForm.controls.QRTAX.setValue(headersale.data[0].QRTAX);
    this.headerSaleForm.controls.IDSTORE.setValue(headersale.data[0].IDSTORE);
    this.headerSaleForm.controls.IDUNIQUE.setValue(
      headersale.data[0].IDUNIQUE
    );
    this.headerSaleForm.controls.IDCOUNTER.setValue(
      headersale.data[0].IDCOUNTER
    );
    this.headerSaleForm.controls.C_DESPAR.setValue(
      headersale.data[0].C_DESPAR
    );

    //------set Adrress-----//
    this.headerSaleForm.controls.C_ADDRESS.setValue(
      headersale.data[0].C_ADDRESS
    );
    this.headerSaleForm.controls.C_TERMCOMP.setValue(
      headersale.data[0].C_TERMCOMP
    );

    this.headerSaleForm.controls.C_REFERENCE.setValue(
      headersale.data[0].C_REFERENCE
    );

    const c_statussale = headersale.data[0].c_statussale

    let parameter = this.listParameters.filter( element => element.C_CODPAR === c_statussale )
    console.log("Mi status:parameter", parameter )
    

    this.headerSaleForm.controls.statusColor.setValue(
      parameter[0].C_DESPAR2
    );

    this.headerSaleForm.controls.statusIcon.setValue(
      parameter[0].C_DESPAR5
    );


    //this.AddressForm.controls.ID.setValue( localStorage.getItem(environment.idcompany) );
    //this.AddressForm.controls.C_ADDCOM.setValue( headersale.data[0].C_ADDCOM  )

    //this.statussale = headersale.data[0].C_DESPAR=='Pendiente'?'Deslice para continuar':headersale.data[0].C_DESPAR;
    //this.payMethodCompany = headersale.payMethod[0].C_DESPAR;

    console.log("HeaderSaleForm value: ", this.headerSaleForm);
    this.idUnique = `${this.headerSaleForm.get("IDUNIQUE").value}${
      this.headerSaleForm.get("IDCOUNTER").value
    }  `;
    //this.subpayment = headersale.subpayment[0];
    //this.customer = headersale.customer[0];

   // console.log("MEtodo de subpago: ", this.subpayment);
    console.log("Cleinte: ", this.customer);
  }

  initializeForms(){
    this.headerSaleForm = this._fb.group({

      C_STATUS: [,[Validators.required]],
      C_STATUSPAYMENT: [,[Validators.required]],
      D_DATSAL: [,[Validators.required]],
      IDHEADERSALE: [,[Validators.required]],
      N_TOTDIS: [,[ ]],
      IDCOMPANY: [,[Validators.required]],
      C_OBSERV: [,],
      N_TOTPAY: [0,[Validators.required]],
      IDCUSTOMER: [,[Validators.required]],
      IDTYPEPAYMENT: [,[Validators.required]],
      IDTYPESUBPAYMENT: [,[Validators.required]],

      statuspayment: ["",[Validators.required]],
      statusorder: ["",[Validators.required]],

      salemethod: ["",[Validators.required]],
      paymethod: ["",[Validators.required]],

      C_ADDREF: ["",[]],
      C_EMACUS: ["",[]],
      C_NUMPHO: ["",],
      C_ADDCUS: ["",],
      N_DELIVERYMOUNT: ["",],

      C_DESSUBPAY:["",[Validators.required]],
      C_NAMACO:["",],

      C_ADDCOM:["",[Validators.maxLength(100) ,Validators.pattern(this.patternDirection)   ] ],
      C_REFERENCE:["",[Validators.maxLength(100) ,Validators.pattern(this.patternDirection)   ] ],
      C_TERM:["",[ ValidatorsService.nonZero, this.validatorsServic.quantity(9) ]],

      C_ADDRESS: ["", [Validators.maxLength(100) ,Validators.pattern(this.patternDirection)   ] ],
      C_TERMCOMP: ["", [ ValidatorsService.nonZero, this.validatorsServic.quantity(9) ]],

      c_statussale: [,[Validators.required]],
      C_NUMDOC: [,],
      N_TOTSAL: [,[Validators.required]],
      C_NAMCLI: ["",[Validators.required]],
      C_PHOCLI: [,],
      N_TOTCAS: [,[Validators.required]],
      C_TYPSAL: [,[Validators.required]],
      N_DISPRO: [,],
      N_TOTCOS: [,[Validators.required]],
      IDPOINTSALES: [,],
      C_STATUSTAX: [,[]],
      C_TYPDOC: [,[]],
      C_NUMBERCPE: [,[]],
      IDREFTAX: [,[]],
      HASHTAX: [,[]],
      MESSAGETAX: [,[]],
      QRTAX: [,[]],
      IDSTORE: [,[]],
      IDUNIQUE: [,[Validators.required]],
      IDCOUNTER: [,[Validators.required]],
      C_DESPAR: ["" ,],
      statusColor: ["" ,],
      statusIcon: ["" ,],
    });

    this.AddressForm = this._fb.group({
      ID: ["",],
      C_ADDCOM:["",[Validators.maxLength(20) ,Validators.pattern(this.patternName)   ]]
    })
  }

  async loadSalesByHeaderId() {
    let request = {
      N_IDHEASAL: this.idheadersale,
      IDCOMPANY: localStorage.getItem(environment.idcompany),
    };

    
      this.requests.push( this.databaseService.getSalesTable(request)  ) //4
    
   /*  .subscribe((sales: any) => {
      this.salesByHeaderSale = sales.data;

        let sum = 0;
        let cost = 0;
        
        this.productsForDiscountStock = [];
        this.salesByHeaderSale.forEach((product) => {
          console.log("Product to sum: ", product);
          //----------------------------------------------------//
          if (product.isSizing == 0) {
            let discount = {
              cantidad: product.cantidad,
              idProduct: product.IDPRODUCT,
              isSizing: product.isSizing,
            };
            this.productsForDiscountStock.push(discount);
          } else {
            let discountWithSize = {
              cantidad: product.cantidad,
              codSiz: product.C_CODSIZ,
              idProduct: product.IDPRODUCT,
              isSizing: product.isSizing,
            };
            this.productsForDiscountStock.push(discountWithSize);
          }

          //----------------------------------------------------//
          sum = sum + +product.N_SUBTOT;
          cost = cost + +(+product.cantidad * +product.N_COST);
        });
    
    
    }); */
    console.log("4");
    
  }

  async loadHeaderSale() {
    let request = {
      IDHEADERSALE: this.idheadersale,
    };

    console.log("Request: ", request );
    

   
    this.requests.push(  this.databaseService.getHeaderSale(request) ) //3
    
    /* .subscribe((headersale: any) => {

      console.log("HeaderSale::(detail) ", headersale);
      this.dateSale = moment(headersale.data[0].D_DATSAL).format(
        "DD/MM/YYYY [a las] HH:mm"
      );

      this.headerSaleForm.controls.C_STATUS.setValue(
        headersale.data[0].C_STATUS
      );

      this.headerSaleForm.controls.C_STATUSPAYMENT.setValue(
        headersale.data[0].C_STATUSPAYMENT
      );

      this.headerSaleForm.controls.D_DATSAL.setValue(
        headersale.data[0].D_DATSAL
      );
      this.headerSaleForm.controls.IDHEADERSALE.setValue(headersale.data[0].ID);
      this.headerSaleForm.controls.N_TOTDIS.setValue(
        headersale.data[0].N_TOTDIS
      );
      this.headerSaleForm.controls.IDCOMPANY.setValue(
        headersale.data[0].IDCOMPANY
      );
      this.headerSaleForm.controls.C_OBSERV.setValue(
        headersale.data[0].C_OBSERV
      );
      this.headerSaleForm.controls.N_TOTPAY.setValue(
        headersale.data[0].N_TOTPAY
      );

      
      this.headerSaleForm.controls.IDCUSTOMER.setValue(
        headersale.data[0].IDCUSTOMER
      );
      this.headerSaleForm.controls.C_ADDCOM.setValue(
        headersale.data[0].C_ADDCOM
      );
      this.headerSaleForm.controls.C_TERM.setValue(
        headersale.data[0].C_TERM
      );


      this.headerSaleForm.controls.IDTYPEPAYMENT.setValue(
        headersale.data[0].IDTYPEPAYMENT
      );
      this.headerSaleForm.controls.IDTYPESUBPAYMENT.setValue(
        headersale.data[0].IDTYPESUBPAYMENT
      );
      this.headerSaleForm.controls.c_statussale.setValue(
        headersale.data[0].c_statussale
      );
      this.headerSaleForm.controls.C_NUMDOC.setValue(
        headersale.data[0].C_NUMDOC
      );
      this.headerSaleForm.controls.N_TOTSAL.setValue(
        headersale.data[0].N_TOTSAL
      );
      this.headerSaleForm.controls.C_NAMCLI.setValue(
        headersale.data[0].C_NAMCLI
      );
      this.headerSaleForm.controls.C_ADDREF.setValue(
        headersale.data[0].C_ADDREF
      );
      this.headerSaleForm.controls.C_EMACUS.setValue(
        headersale.data[0].C_EMACUS
      );
      this.headerSaleForm.controls.C_NUMPHO.setValue(
        headersale.data[0].C_NUMPHO
      );
      this.headerSaleForm.controls.C_ADDCUS.setValue(
        headersale.data[0].C_ADDCUS
      );




      this.headerSaleForm.controls.C_PHOCLI.setValue(
        headersale.data[0].C_PHOCLI
      );
      this.headerSaleForm.controls.N_TOTCAS.setValue(
        headersale.data[0].N_TOTCAS
      );
      this.headerSaleForm.controls.C_TYPSAL.setValue(
        headersale.data[0].C_TYPSAL
      );
      this.headerSaleForm.controls.N_DISPRO.setValue(
        headersale.data[0].N_DISPRO
      );
      this.headerSaleForm.controls.N_TOTCOS.setValue(
        headersale.data[0].N_TOTCOS
      );
      this.headerSaleForm.controls.IDPOINTSALES.setValue(
        headersale.data[0].IDPOINTSALES
      );
      this.headerSaleForm.controls.C_STATUSTAX.setValue(
        headersale.data[0].C_STATUSTAX
      );
      this.headerSaleForm.controls.C_TYPDOC.setValue(
        headersale.data[0].C_TYPDOC
      );
      this.headerSaleForm.controls.C_NUMBERCPE.setValue(
        headersale.data[0].C_NUMBERCPE
      );
      this.headerSaleForm.controls.IDREFTAX.setValue(
        headersale.data[0].IDREFTAX
      );

      this.headerSaleForm.controls.HASHTAX.setValue(headersale.data[0].HASHTAX);
      this.headerSaleForm.controls.MESSAGETAX.setValue(
        headersale.data[0].MESSAGETAX
      );

      this.headerSaleForm.controls.statuspayment.setValue(headersale.data[0].statuspayment);
      this.headerSaleForm.controls.statusorder.setValue(headersale.data[0].statusorder);

      this.headerSaleForm.controls.salemethod.setValue(headersale.data[0].salemethod);
      this.headerSaleForm.controls.paymethod.setValue(headersale.data[0].paymethod);


      this.headerSaleForm.controls.C_DESSUBPAY.setValue(headersale.data[0].C_DESSUBPAY);
      this.headerSaleForm.controls.C_NAMACO.setValue(headersale.data[0].C_NAMACO);


      this.headerSaleForm.controls.QRTAX.setValue(headersale.data[0].QRTAX);
      this.headerSaleForm.controls.IDSTORE.setValue(headersale.data[0].IDSTORE);
      this.headerSaleForm.controls.IDUNIQUE.setValue(
        headersale.data[0].IDUNIQUE
      );
      this.headerSaleForm.controls.IDCOUNTER.setValue(
        headersale.data[0].IDCOUNTER
      );
      this.headerSaleForm.controls.C_DESPAR.setValue(
        headersale.data[0].C_DESPAR
      );

      this.AddressForm.controls.ID.setValue( localStorage.getItem(environment.idcompany) );
      this.AddressForm.controls.C_ADDCOM.setValue( headersale.data[0].C_ADDCOM  )

      //this.statussale = headersale.data[0].C_DESPAR=='Pendiente'?'Deslice para continuar':headersale.data[0].C_DESPAR;
      //this.payMethodCompany = headersale.payMethod[0].C_DESPAR;

      console.log("HeaderSaleForm value: ", this.headerSaleForm);
      this.idUnique = `${this.headerSaleForm.get("IDUNIQUE").value}${
        this.headerSaleForm.get("IDCOUNTER").value
      }  `;
      //this.subpayment = headersale.subpayment[0];
      //this.customer = headersale.customer[0];

     // console.log("MEtodo de subpago: ", this.subpayment);
      console.log("Cleinte: ", this.customer);
    }); */
    console.log("3");
    
  }

  segmentChanged(event) {
    console.log(event.detail.value);
    this.selectTabs = event.detail.value;
  }

  emitEventToChild() {
    console.log("Emitimoss!");
    this.close();
    this.eventsSubject.next();
  }

  listParameters = [];

  getParameters() {
    let request = {
      C_CODDOM: "D001",
    
    };

   
    this.requests.push(  this.databaseService
      .getParameters(request) ) //2



      /* .pipe(map((params) => params.filter((e) => Number(e.C_CODPAR) > 0)))
      .subscribe((rpta: any) => {
        console.log("rpta Lista paramttros: ", rpta);
        this.listParameters = rpta;
      }); */

console.log("2");

  }

  goBack() {
    //this.location.back();
    this.modalCtrl.dismiss();
  }


  async statusSelected( status  ) {
    console.log("status_codpar: ", status );
    console.log("original Frpomdata: ", this.headerSaleForm.value );


      let request = {
        ...this.headerSaleForm.value,
       // C_STATUSPAYMENT:'1',
        //discount_stock: this.headerSaleForm.controls.C_STATUSPAYMENT.value==0?'1':'0',
        c_statussale:status.C_CODPAR  ,
      };

      console.log("request shageStatus: ", request );
      
      await this.loaderService.show('Actualizando venta...')
    this.updHeaderSalesSubs3 =  this.databaseService.updHeaderSale(request)
    .pipe( 
      takeUntil( this.unsubscribeSignal.asObservable() )
      )
    .subscribe((headersale: any) => {
        this.loaderService.hide();
        if (headersale.status == "true") {
          console.log("Se actualizo correctamente? : ", headersale);
          this.presentToast("Se actualizó el status a: " + headersale.data[0].statusorder );
          // this.closeModal();
           
          this.headerSaleForm.controls.C_STATUS.setValue(
            headersale.data[0].C_STATUS
          );
    
          this.headerSaleForm.controls.C_STATUSPAYMENT.setValue(
            headersale.data[0].C_STATUSPAYMENT
          );
    
          this.headerSaleForm.controls.D_DATSAL.setValue(
            headersale.data[0].D_DATSAL
          );
          this.headerSaleForm.controls.IDHEADERSALE.setValue(headersale.data[0].ID);
          this.headerSaleForm.controls.N_TOTDIS.setValue(
            headersale.data[0].N_TOTDIS
          );
          this.headerSaleForm.controls.IDCOMPANY.setValue(
            headersale.data[0].IDCOMPANY
          );
          this.headerSaleForm.controls.C_OBSERV.setValue(
            headersale.data[0].C_OBSERV
          );
          this.headerSaleForm.controls.N_TOTPAY.setValue(
            headersale.data[0].N_TOTPAY
          );
          this.headerSaleForm.controls.IDCUSTOMER.setValue(
            headersale.data[0].IDCUSTOMER
          );
          this.headerSaleForm.controls.IDTYPEPAYMENT.setValue(
            headersale.data[0].IDTYPEPAYMENT
          );
          this.headerSaleForm.controls.IDTYPESUBPAYMENT.setValue(
            headersale.data[0].IDTYPESUBPAYMENT
          );
          this.headerSaleForm.controls.c_statussale.setValue(
            headersale.data[0].c_statussale
          );
          this.headerSaleForm.controls.C_NUMDOC.setValue(
            headersale.data[0].C_NUMDOC
          );
          this.headerSaleForm.controls.N_TOTSAL.setValue(
            headersale.data[0].N_TOTSAL
          );

          this.headerSaleForm.controls.C_ADDCOM.setValue(
            headersale.data[0].C_ADDCOM
          );
          this.headerSaleForm.controls.C_TERM.setValue(
            headersale.data[0].C_TERM
          );
    
    
    
    
          this.headerSaleForm.controls.C_NAMCLI.setValue(
            headersale.data[0].C_NAMCLI
          );
          this.headerSaleForm.controls.C_ADDREF.setValue(
            headersale.data[0].C_ADDREF
          );
          this.headerSaleForm.controls.C_EMACUS.setValue(
            headersale.data[0].C_EMACUS
          );
          this.headerSaleForm.controls.C_NUMPHO.setValue(
            headersale.data[0].C_NUMPHO
          );
          this.headerSaleForm.controls.C_ADDCUS.setValue(
            headersale.data[0].C_ADDCUS
          );
    
    
    
    
          this.headerSaleForm.controls.C_PHOCLI.setValue(
            headersale.data[0].C_PHOCLI
          );
          this.headerSaleForm.controls.N_TOTCAS.setValue(
            headersale.data[0].N_TOTCAS
          );
          this.headerSaleForm.controls.C_TYPSAL.setValue(
            headersale.data[0].C_TYPSAL
          );
          this.headerSaleForm.controls.N_DISPRO.setValue(
            headersale.data[0].N_DISPRO
          );
          this.headerSaleForm.controls.N_TOTCOS.setValue(
            headersale.data[0].N_TOTCOS
          );
          this.headerSaleForm.controls.IDPOINTSALES.setValue(
            headersale.data[0].IDPOINTSALES
          );
          this.headerSaleForm.controls.C_STATUSTAX.setValue(
            headersale.data[0].C_STATUSTAX
          );
          this.headerSaleForm.controls.C_TYPDOC.setValue(
            headersale.data[0].C_TYPDOC
          );
          this.headerSaleForm.controls.C_NUMBERCPE.setValue(
            headersale.data[0].C_NUMBERCPE
          );
          this.headerSaleForm.controls.IDREFTAX.setValue(
            headersale.data[0].IDREFTAX
          );
    
          this.headerSaleForm.controls.HASHTAX.setValue(headersale.data[0].HASHTAX);
          this.headerSaleForm.controls.MESSAGETAX.setValue(
            headersale.data[0].MESSAGETAX
          );
    
          this.headerSaleForm.controls.statuspayment.setValue(headersale.data[0].statuspayment);
          this.headerSaleForm.controls.statusorder.setValue(headersale.data[0].statusorder);
    
          this.headerSaleForm.controls.salemethod.setValue(headersale.data[0].salemethod);
          this.headerSaleForm.controls.paymethod.setValue(headersale.data[0].paymethod);
    
    
          this.headerSaleForm.controls.C_DESSUBPAY.setValue(headersale.data[0].C_DESSUBPAY);
          this.headerSaleForm.controls.C_NAMACO.setValue(headersale.data[0].C_NAMACO);
    
    
          this.headerSaleForm.controls.QRTAX.setValue(headersale.data[0].QRTAX);
          this.headerSaleForm.controls.IDSTORE.setValue(headersale.data[0].IDSTORE);
          this.headerSaleForm.controls.IDUNIQUE.setValue(
            headersale.data[0].IDUNIQUE
          );

          this.headerSaleForm.controls.IDCOUNTER.setValue(
            headersale.data[0].IDCOUNTER
          );
          this.headerSaleForm.controls.C_DESPAR.setValue(
            headersale.data[0].C_DESPAR
          );

          //-------------------------------------------------------------------------//
          this.headerSaleForm.controls.C_ADDRESS.setValue(
            headersale.data[0].C_ADDRESS
          );
          this.headerSaleForm.controls.C_TERMCOMP.setValue(
            headersale.data[0].C_TERMCOMP
          );
        
          this.headerSaleForm.controls.statusColor.setValue(
          status.C_DESPAR2
          );

          this.headerSaleForm.controls.statusIcon.setValue(
          status.C_DESPAR5
          );
        
          setTimeout(() => {
              this.close();

              /* if( status.C_CODPAR == '4'){
                this.modalCtrl.dismiss();
                this.router.navigate(['pending-finished'])
              } */
              

          }, 800);


        } else {
          this.presentToast("No se logro actualizar.");
          // this.closeModal();
        }

      });   
    
  }

  isWait = false;

  updAddcomCategory(){
    this.isWait = true
    setTimeout(() => {
      this.isWait = false
    }, 2000);
  }


 async changePaymentState(){

      let request = {
        ...this.headerSaleForm.value,
       // C_STATUSPAYMENT:'1',
        discount_stock: this.headerSaleForm.controls.C_STATUSPAYMENT.value==3?'0':'1',//si es 3 ya fue confirmado antes
        C_STATUSPAYMENT:3,
        toDiscount: this.productsForDiscountStock,
      };

      console.log("request shageStatus(CONFIRM): ", request );
      
      await this.loaderService.show('Actualizando venta...')

      this.updHeaderSalesubs2 = this.databaseService.updHeaderSale(request)
      .pipe( 
        takeUntil( this.unsubscribeSignal.asObservable() )
        )
      .subscribe((headersale: any) => {
        this.loaderService.hide();
        if (headersale.status == "true") {

          console.log("Se actualizo correctamente?(confirm) : ", headersale);
          this.presentToast("Se actualizó el registro.");
          // this.closeModal();
          this.headerSaleForm.controls.C_STATUS.setValue(
            headersale.data[0].C_STATUS
          );
    
          this.headerSaleForm.controls.C_STATUSPAYMENT.setValue(
            headersale.data[0].C_STATUSPAYMENT
          );
    
          this.headerSaleForm.controls.D_DATSAL.setValue(
            headersale.data[0].D_DATSAL
          );
          this.headerSaleForm.controls.IDHEADERSALE.setValue(headersale.data[0].ID);
          this.headerSaleForm.controls.N_TOTDIS.setValue(
            headersale.data[0].N_TOTDIS
          );
          this.headerSaleForm.controls.IDCOMPANY.setValue(
            headersale.data[0].IDCOMPANY
          );
          this.headerSaleForm.controls.C_OBSERV.setValue(
            headersale.data[0].C_OBSERV
          );
          this.headerSaleForm.controls.N_TOTPAY.setValue(
            headersale.data[0].N_TOTPAY
          );
          this.headerSaleForm.controls.IDCUSTOMER.setValue(
            headersale.data[0].IDCUSTOMER
          );
          this.headerSaleForm.controls.IDTYPEPAYMENT.setValue(
            headersale.data[0].IDTYPEPAYMENT
          );
          this.headerSaleForm.controls.IDTYPESUBPAYMENT.setValue(
            headersale.data[0].IDTYPESUBPAYMENT
          );
          this.headerSaleForm.controls.c_statussale.setValue(
            headersale.data[0].c_statussale
          );
          this.headerSaleForm.controls.C_NUMDOC.setValue(
            headersale.data[0].C_NUMDOC
          );
          this.headerSaleForm.controls.N_TOTSAL.setValue(
            headersale.data[0].N_TOTSAL
          );
    
          this.headerSaleForm.controls.C_ADDCOM.setValue(
            headersale.data[0].C_ADDCOM
          );
          this.headerSaleForm.controls.C_TERM.setValue(
            headersale.data[0].C_TERM
          );
    
    
    
          this.headerSaleForm.controls.C_NAMCLI.setValue(
            headersale.data[0].C_NAMCLI
          );
          this.headerSaleForm.controls.C_ADDREF.setValue(
            headersale.data[0].C_ADDREF
          );
          this.headerSaleForm.controls.C_EMACUS.setValue(
            headersale.data[0].C_EMACUS
          );
          this.headerSaleForm.controls.C_NUMPHO.setValue(
            headersale.data[0].C_NUMPHO
          );
          this.headerSaleForm.controls.C_ADDCUS.setValue(
            headersale.data[0].C_ADDCUS
          );
    
    
    
    
          this.headerSaleForm.controls.C_PHOCLI.setValue(
            headersale.data[0].C_PHOCLI
          );
          this.headerSaleForm.controls.N_TOTCAS.setValue(
            headersale.data[0].N_TOTCAS
          );
          this.headerSaleForm.controls.C_TYPSAL.setValue(
            headersale.data[0].C_TYPSAL
          );
          this.headerSaleForm.controls.N_DISPRO.setValue(
            headersale.data[0].N_DISPRO
          );
          this.headerSaleForm.controls.N_TOTCOS.setValue(
            headersale.data[0].N_TOTCOS
          );
          this.headerSaleForm.controls.IDPOINTSALES.setValue(
            headersale.data[0].IDPOINTSALES
          );
          this.headerSaleForm.controls.C_STATUSTAX.setValue(
            headersale.data[0].C_STATUSTAX
          );
          this.headerSaleForm.controls.C_TYPDOC.setValue(
            headersale.data[0].C_TYPDOC
          );
          this.headerSaleForm.controls.C_NUMBERCPE.setValue(
            headersale.data[0].C_NUMBERCPE
          );
          this.headerSaleForm.controls.IDREFTAX.setValue(
            headersale.data[0].IDREFTAX
          );
    
          this.headerSaleForm.controls.HASHTAX.setValue(headersale.data[0].HASHTAX);
          this.headerSaleForm.controls.MESSAGETAX.setValue(
            headersale.data[0].MESSAGETAX
          );
    
          this.headerSaleForm.controls.statuspayment.setValue(headersale.data[0].statuspayment);
          this.headerSaleForm.controls.statusorder.setValue(headersale.data[0].statusorder);
    
          this.headerSaleForm.controls.salemethod.setValue(headersale.data[0].salemethod);
          this.headerSaleForm.controls.paymethod.setValue(headersale.data[0].paymethod);
    
    
          this.headerSaleForm.controls.C_DESSUBPAY.setValue(headersale.data[0].C_DESSUBPAY);
          this.headerSaleForm.controls.C_NAMACO.setValue(headersale.data[0].C_NAMACO);
    
    
          this.headerSaleForm.controls.QRTAX.setValue(headersale.data[0].QRTAX);
          this.headerSaleForm.controls.IDSTORE.setValue(headersale.data[0].IDSTORE);
          this.headerSaleForm.controls.IDUNIQUE.setValue(
            headersale.data[0].IDUNIQUE
          );
          this.headerSaleForm.controls.IDCOUNTER.setValue(
            headersale.data[0].IDCOUNTER
          );
          this.headerSaleForm.controls.C_DESPAR.setValue(
            headersale.data[0].C_DESPAR
          );

        } else {
          this.presentToast("No se logro actualizar.");
          // this.closeModal();
        }

      });
  }


  getDetails() {
    let request = {
      IDCOMPANY: localStorage.getItem(environment.idcompany),
    };
   
    //this.requests.push(  this.databaseService.getCompanyById(request)  )//1
    
    //getDEtails
    /* .subscribe(
      (rptaCompany: any) => {
        console.log("REmoinder (orderspage): ", rptaCompany);
        this.companyAddress = rptaCompany.data[0].C_ADDCOM;
        this.termOfCompany = rptaCompany.data[0].C_TERM;
      },
      (err) => {
        console.log("error al extraer compañia: ", err);
      }
    ) */;
    console.log("1");
    
  }

  async confirmPaymentAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Confirmación de pago",
      subHeader: "¿Esta seguro en confirmar el pago?",

      //message: 'This is an alert message.',
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          },
        },
        {
          text: "Sí, confirmo",
          handler: () => {
            this.changePaymentState()
          },
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    console.log("onDidDismiss resolved with role", role);
  }


  
  async updAddressTermCompany(){

    for (let u in this.headerSaleForm.controls) {
      this.headerSaleForm.controls[u].markAsTouched();
      if( this.headerSaleForm.controls[u].hasError('required') ){
        console.log("uuu: ",u );
        
      }
    }


    if( this.headerSaleForm.valid ){
      this.isWait = true;
      console.log("Form Valido: ", this.headerSaleForm.valid );

      let request = {
        ...this.headerSaleForm.value,
        //updCompany:'1'
      }

      console.log("REGUEST: ",request );
      
      //await this.loaderService.show('Actualizando venta...')
     this.updHeaderSalesubs = this.databaseService.updHeaderSale( request )
     .pipe( 
      takeUntil( this.unsubscribeSignal.asObservable() )
      )
     .subscribe( (headersale:any)=>{
        console.log("RPTA SELCT Typsal: ",headersale);
        //this.loaderService.hide();
        this.isWait = false;
        //this.router.navigate(["cart"]);
        this.closeAddress();
        this.closeTerm();
        this.closeREFERENCE();
        this.presentToast('Se actualizó correctamente.')
        this.headerSaleForm.controls.C_STATUS.setValue(
          headersale.data[0].C_STATUS
        );
  
        this.headerSaleForm.controls.C_STATUSPAYMENT.setValue(
          headersale.data[0].C_STATUSPAYMENT
        );
  
        this.headerSaleForm.controls.D_DATSAL.setValue(
          headersale.data[0].D_DATSAL
        );
        this.headerSaleForm.controls.IDHEADERSALE.setValue(headersale.data[0].ID);
        this.headerSaleForm.controls.N_TOTDIS.setValue(
          headersale.data[0].N_TOTDIS
        );
        this.headerSaleForm.controls.IDCOMPANY.setValue(
          headersale.data[0].IDCOMPANY
        );
        this.headerSaleForm.controls.C_OBSERV.setValue(
          headersale.data[0].C_OBSERV
        );
        this.headerSaleForm.controls.N_TOTPAY.setValue(
          headersale.data[0].N_TOTPAY
        );
  
        
        this.headerSaleForm.controls.IDCUSTOMER.setValue(
          headersale.data[0].IDCUSTOMER
        );
        this.headerSaleForm.controls.C_ADDCOM.setValue(
          headersale.data[0].C_ADDCOM
        );
        this.headerSaleForm.controls.C_TERM.setValue(
          headersale.data[0].C_TERM
        );
  
  
        this.headerSaleForm.controls.IDTYPEPAYMENT.setValue(
          headersale.data[0].IDTYPEPAYMENT
        );
        this.headerSaleForm.controls.IDTYPESUBPAYMENT.setValue(
          headersale.data[0].IDTYPESUBPAYMENT
        );
        this.headerSaleForm.controls.c_statussale.setValue(
          headersale.data[0].c_statussale
        );
        this.headerSaleForm.controls.C_NUMDOC.setValue(
          headersale.data[0].C_NUMDOC
        );
        this.headerSaleForm.controls.N_TOTSAL.setValue(
          headersale.data[0].N_TOTSAL
        );
  
  
  
        this.headerSaleForm.controls.C_NAMCLI.setValue(
          headersale.data[0].C_NAMCLI
        );
        this.headerSaleForm.controls.C_ADDREF.setValue(
          headersale.data[0].C_ADDREF
        );
        this.headerSaleForm.controls.C_EMACUS.setValue(
          headersale.data[0].C_EMACUS
        );
        this.headerSaleForm.controls.C_NUMPHO.setValue(
          headersale.data[0].C_NUMPHO
        );
        this.headerSaleForm.controls.C_ADDCUS.setValue(
          headersale.data[0].C_ADDCUS
        );
  
  
  
  
        this.headerSaleForm.controls.C_PHOCLI.setValue(
          headersale.data[0].C_PHOCLI
        );
        this.headerSaleForm.controls.N_TOTCAS.setValue(
          headersale.data[0].N_TOTCAS
        );
        this.headerSaleForm.controls.C_TYPSAL.setValue(
          headersale.data[0].C_TYPSAL
        );
        this.headerSaleForm.controls.N_DISPRO.setValue(
          headersale.data[0].N_DISPRO
        );
        this.headerSaleForm.controls.N_TOTCOS.setValue(
          headersale.data[0].N_TOTCOS
        );
        this.headerSaleForm.controls.IDPOINTSALES.setValue(
          headersale.data[0].IDPOINTSALES
        );
        this.headerSaleForm.controls.C_STATUSTAX.setValue(
          headersale.data[0].C_STATUSTAX
        );
        this.headerSaleForm.controls.C_TYPDOC.setValue(
          headersale.data[0].C_TYPDOC
        );
        this.headerSaleForm.controls.C_NUMBERCPE.setValue(
          headersale.data[0].C_NUMBERCPE
        );
        this.headerSaleForm.controls.IDREFTAX.setValue(
          headersale.data[0].IDREFTAX
        );
  
        this.headerSaleForm.controls.HASHTAX.setValue(headersale.data[0].HASHTAX);
        this.headerSaleForm.controls.MESSAGETAX.setValue(
          headersale.data[0].MESSAGETAX
        );
  
        this.headerSaleForm.controls.statuspayment.setValue(headersale.data[0].statuspayment);
        this.headerSaleForm.controls.statusorder.setValue(headersale.data[0].statusorder);
  
        this.headerSaleForm.controls.salemethod.setValue(headersale.data[0].salemethod);
        this.headerSaleForm.controls.paymethod.setValue(headersale.data[0].paymethod);
  
  
        this.headerSaleForm.controls.C_DESSUBPAY.setValue(headersale.data[0].C_DESSUBPAY);
        this.headerSaleForm.controls.C_NAMACO.setValue(headersale.data[0].C_NAMACO);
  
  
        this.headerSaleForm.controls.QRTAX.setValue(headersale.data[0].QRTAX);
        this.headerSaleForm.controls.IDSTORE.setValue(headersale.data[0].IDSTORE);
        this.headerSaleForm.controls.IDUNIQUE.setValue(
          headersale.data[0].IDUNIQUE
        );
        this.headerSaleForm.controls.IDCOUNTER.setValue(
          headersale.data[0].IDCOUNTER
        );
        this.headerSaleForm.controls.C_DESPAR.setValue(
          headersale.data[0].C_DESPAR
        );

        
          //-------------------------------------------------------------------------//
          this.headerSaleForm.controls.C_ADDRESS.setValue(
            headersale.data[0].C_ADDRESS
          );
          this.headerSaleForm.controls.C_TERMCOMP.setValue(
            headersale.data[0].C_TERMCOMP
          );
        



      }  , err=>this.isWait = false)
      
    }else{
      console.log("No valodi: ", this.headerSaleForm );
      
    }
  
  }
  



  /* ------------------------- MODAL CHANGE c_statussale ---------------------*/

  @ViewChild("bgOrder", { read: ElementRef }) bgOrder: ElementRef;
  @ViewChild("bottomSheetOrder", { read: ElementRef })
  bottomSheetOrder: ElementRef;
  open(e) {
    console.log("ee: ", e);
    this.renderer.setStyle(
      this.bottomSheetOrder.nativeElement,
      "-webkit-transform",
      "translate3d(0px,0px,0px)"
    );
    this.renderer.setStyle(
      this.bottomSheetOrder.nativeElement,
      "transform",
      "translate3d(0px,0%,0px)"
    );
    this.renderer.setStyle(
      this.bottomSheetOrder.nativeElement,
      "-webkit-transition-duration",
      "0.5s"
    );
    this.renderer.setStyle(
      this.bottomSheetOrder.nativeElement,
      "transition-duration",
      "0.5s"
    );
    this.renderer.setStyle(this.bgOrder.nativeElement, "display", "block");
  }

  close() {
    this.currentPosition = 0;
    this.startPosition = 0;

    (<HTMLStyleElement>(
      document.querySelector(".bottomSheetOrder")
    )).style.transform = "translate3d(0px,100%,0px)";
    (<HTMLStyleElement>document.querySelector(".bgOrder")).style.display =
      "none";
  }

  touchMove(evt: TouchEvent) {
    console.log("Touchmove");
    if (this.startPosition == 0) {
      this.startPosition = evt.touches[0].clientY;
    }
    this.height = document.querySelector(".bottomSheetOrder").clientHeight;

    let y = evt.touches[0].clientY;

    this.currentPosition = y - this.startPosition;
    //console.log("currentPosition: ", this.currentPosition );

    if (this.currentPosition > 0 && this.startPosition > 0) {
      (<HTMLStyleElement>(
        document.querySelector(".bottomSheetOrder")
      )).style.transform = `translate3d(0px,${this.currentPosition}px,0px)`;
    }
  }

  touchEnd() {
    this.minimumThreshold = this.height - 130;

    if (this.height) {
      if (this.currentPosition < this.minimumThreshold) {
        (<HTMLStyleElement>(
          document.querySelector(".bottomSheetOrder")
        )).style.transform = `translate3d(0px,0px,0px)`;
      } else {
        this.close();
      }
    }
  }
 
  //-------------------------------- Modal Change Adress ---------------------------------------//
  @ViewChild("bgAddress", { read: ElementRef }) bgAddress: ElementRef;
  @ViewChild("bottomSheetAddress", { read: ElementRef })
  bottomSheetAddress: ElementRef;
  openAddress(e) {
    console.log("ee: ", e);
    this.renderer.setStyle(
      this.bottomSheetAddress.nativeElement,
      "-webkit-transform",
      "translate3d(0px,0px,0px)"
    );
    this.renderer.setStyle(
      this.bottomSheetAddress.nativeElement,
      "transform",
      "translate3d(0px,0%,0px)"
    );
    this.renderer.setStyle(
      this.bottomSheetAddress.nativeElement,
      "-webkit-transition-duration",
      "0.5s"
    );
    this.renderer.setStyle(
      this.bottomSheetAddress.nativeElement,
      "transition-duration",
      "0.5s"
    );
    this.renderer.setStyle(this.bgAddress.nativeElement, "display", "block");
  }

  closeAddress() {
    this.currentPosition = 0;
    this.startPosition = 0;

    (<HTMLStyleElement>(
      document.querySelector(".bottomSheetAddress")
    )).style.transform = "translate3d(0px,100%,0px)";
    (<HTMLStyleElement>document.querySelector(".bgAddress")).style.display =
      "none";
  }

  touchMoveAddress(evt: TouchEvent) {
    console.log("Touchmove");
    if (this.startPosition == 0) {
      this.startPosition = evt.touches[0].clientY;
    }
    this.height = document.querySelector(".bottomSheetAddress").clientHeight;

    let y = evt.touches[0].clientY;

    this.currentPosition = y - this.startPosition;
    //console.log("currentPosition: ", this.currentPosition );

    if (this.currentPosition > 0 && this.startPosition > 0) {
      (<HTMLStyleElement>(
        document.querySelector(".bottomSheetAddress")
      )).style.transform = `translate3d(0px,${this.currentPosition}px,0px)`;
    }
  }

  touchEndAddress() {
    this.minimumThreshold = this.height - 75;

    if (this.height) {
      if (this.currentPosition < this.minimumThreshold) {
        (<HTMLStyleElement>(
          document.querySelector(".bottomSheetAddress")
        )).style.transform = `translate3d(0px,0px,0px)`;
      } else {
        this.closeAddress();
      }
    }
  }

  //------------------------------ Modal Term Address ----------------------------------------------//
  @ViewChild("bgTerm", { read: ElementRef }) bgTerm: ElementRef;
  @ViewChild("bottomSheetTerm", { read: ElementRef })
  bottomSheetTerm: ElementRef;
  openTerm(e) {
    console.log("ee: ", e);
    this.renderer.setStyle(
      this.bottomSheetTerm.nativeElement,
      "-webkit-transform",
      "translate3d(0px,0px,0px)"
    );
    this.renderer.setStyle(
      this.bottomSheetTerm.nativeElement,
      "transform",
      "translate3d(0px,0%,0px)"
    );
    this.renderer.setStyle(
      this.bottomSheetTerm.nativeElement,
      "-webkit-transition-duration",
      "0.5s"
    );
    this.renderer.setStyle(
      this.bottomSheetTerm.nativeElement,
      "transition-duration",
      "0.5s"
    );
    this.renderer.setStyle(this.bgTerm.nativeElement, "display", "block");
  }

  closeTerm() {
    this.currentPosition = 0;
    this.startPosition = 0;

    (<HTMLStyleElement>(
      document.querySelector(".bottomSheetTerm")
    )).style.transform = "translate3d(0px,100%,0px)";
    (<HTMLStyleElement>document.querySelector(".bgTerm")).style.display =
      "none";
  }

  touchMoveTerm(evt: TouchEvent) {
    console.log("Touchmove");
    if (this.startPosition == 0) {
      this.startPosition = evt.touches[0].clientY;
    }
    this.height = document.querySelector(".bottomSheetTerm").clientHeight;

    let y = evt.touches[0].clientY;

    this.currentPosition = y - this.startPosition;
    //console.log("currentPosition: ", this.currentPosition );

    if (this.currentPosition > 0 && this.startPosition > 0) {
      (<HTMLStyleElement>(
        document.querySelector(".bottomSheetTerm")
      )).style.transform = `translate3d(0px,${this.currentPosition}px,0px)`;
    }
  }

  touchEndTerm() {
    this.minimumThreshold = this.height - 75;

    if (this.height) {
      if (this.currentPosition < this.minimumThreshold) {
        (<HTMLStyleElement>(
          document.querySelector(".bottomSheetTerm")
        )).style.transform = `translate3d(0px,0px,0px)`;
      } else {
        this.closeTerm();
      }
    }
  }
  //------------------------------ Modal REFERENCE ----------------------------------------------//
  @ViewChild("bgREFERENCE", { read: ElementRef }) bgREFERENCE: ElementRef;
  @ViewChild("bottomSheetREFERENCE", { read: ElementRef })
  bottomSheetREFERENCE: ElementRef;
  openREFERENCE(e) {
    console.log("ee: ", e);
    this.renderer.setStyle(
      this.bottomSheetREFERENCE.nativeElement,
      "-webkit-transform",
      "translate3d(0px,0px,0px)"
    );
    this.renderer.setStyle(
      this.bottomSheetREFERENCE.nativeElement,
      "transform",
      "translate3d(0px,0%,0px)"
    );
    this.renderer.setStyle(
      this.bottomSheetREFERENCE.nativeElement,
      "-webkit-transition-duration",
      "0.5s"
    );
    this.renderer.setStyle(
      this.bottomSheetREFERENCE.nativeElement,
      "transition-duration",
      "0.5s"
    );
    this.renderer.setStyle(this.bgREFERENCE.nativeElement, "display", "block");
  }

  closeREFERENCE() {
    this.currentPosition = 0;
    this.startPosition = 0;

    (<HTMLStyleElement>(
      document.querySelector(".bottomSheetREFERENCE")
    )).style.transform = "translate3d(0px,100%,0px)";
    (<HTMLStyleElement>document.querySelector(".bgREFERENCE")).style.display =
      "none";
  }

  touchMoveREFERENCE(evt: TouchEvent) {
    console.log("Touchmove");
    if (this.startPosition == 0) {
      this.startPosition = evt.touches[0].clientY;
    }
    this.height = document.querySelector(".bottomSheetREFERENCE").clientHeight;

    let y = evt.touches[0].clientY;

    this.currentPosition = y - this.startPosition;
    //console.log("currentPosition: ", this.currentPosition );

    if (this.currentPosition > 0 && this.startPosition > 0) {
      (<HTMLStyleElement>(
        document.querySelector(".bottomSheetREFERENCE")
      )).style.transform = `translate3d(0px,${this.currentPosition}px,0px)`;
    }
  }

  touchEndREFERENCE() {
    this.minimumThreshold = this.height - 75;

    if (this.height) {
      if (this.currentPosition < this.minimumThreshold) {
        (<HTMLStyleElement>(
          document.querySelector(".bottomSheetREFERENCE")
        )).style.transform = `translate3d(0px,0px,0px)`;
      } else {
        this.closeREFERENCE();
      }
    }
  }




  getIcon(c_despar):any {
    if( c_despar == "Pedido Realizado"){

      let data = {
        icon:'cart',
        color:'#FF00FF'
      }
      return data;
    }else if( c_despar == "Listo para entregar"){
      let data = {
        icon:'bag',
        color:'#FF9900'
      }
      return data;
    }else if(  c_despar == "En delivery" ){
      let data = {
        icon:'bicycle',
        color:'#FFFF00'
      }
      return data;
    }else if(  c_despar ==  "Entregado" ){
      let data = {
        icon:'checkmark',
        color:'#00FF00'
      }
      return data;
    }else if( c_despar ==  "Anulado"){
      let data = {
        icon:'trash',
        color:'#FF0000'
      }
      return data;
    }else{
        console.log("N.A.");
        let data = {
          icon:'eye',
          color:'brown'
        }
        return data;
    }

 
  }















  closeModal() {
    this.databaseService.sendCartEvent()
    this.modalCtrl.dismiss();
  }

  async presentToast(msm: string) {
    const toast = await this.toastController.create({
      message: msm,
      position: "middle",
      duration: 2000,
      color:"warning"
    });
    toast.present();
  }


  goToMainPage(){
    this.router.navigate(['pending-finished']);
    this.modalCtrl.dismiss();
  }














}
