import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController, ToastController } from '@ionic/angular';
import { CreditNoteModalPage } from 'src/app/pages/credit-note/credit-note-modal/credit-note-modal.page';
import { DatabaseService } from 'src/app/services/database.service';
import { InterpageService } from 'src/app/services/interpage.service';

@Component({
  selector: 'app-pop-credit',
  templateUrl: './pop-credit.component.html',
  styleUrls: ['./pop-credit.component.scss'],
})
export class PopCreditComponent implements OnInit {
  @Input("discountProd") discountProd;

  constructor(
    private popoverController: PopoverController,
    private _dataService: DatabaseService,
    private router: Router,
    private toastCtrl: ToastController,
    private interPageService: InterpageService,
    private modalController: ModalController
  ) { }

  ngOnInit() {

    console.log("Me pasaste esto: " , this.discountProd );
    

  }




  async selectedTypeDevolution( ){
    let product = this.discountProd;
   
    console.log("Product To devoluiion: ", this.discountProd );

    let discountProd ={
      idProduct: product.idProduct,       
      idHeaderSale: product.idHeaderSale,  
      idCreditNote: product.idCreditNote,
      idCreditNoteSale: product.idCreditNoteSale,
      devueltos: product.devueltos || 0,
      codSiz:product.codSiz,
      numberProducts:product.numberProducts
      //restantes: (Number(product.cantidad) || 0) - ( Number(product.devueltos) || 0)
    }

    console.log("Al modal de crdit: ", discountProd );
    
    

      //abrimos el modal
      const modal = await this.modalController.create({
        component: CreditNoteModalPage,
        cssClass: 'my-custom-class',
        componentProps:{
          discountProd :{
            idProduct: product.idProduct,       
            idHeaderSale: product.idHeaderSale,  
            idCreditNote: product.idCreditNote,
            idCreditNoteSale: product.idCreditNoteSale,
            devueltos: product.devueltos || 0,
            codSiz:product.codSiz,
            numberProducts:product.numberProducts
            //restantes: (Number(product.cantidad) || 0) - ( Number(product.devueltos) || 0)
          }
        }
      }); 
    

   modal.onDidDismiss().then( (data)=>{
    console.log("se cerro el modal de cridit Noe(popover): ",  );
    this.popoverController.dismiss(data.data);

   } )

   return await modal.present().then(()=>{
    // this.popover=null;
   })


 
  } 

}
