import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { from, Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { map, switchMap } from "rxjs/operators";
import { isNullOrUndefined } from "util";
import { Platform } from "@ionic/angular";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TypeSaleGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<UrlTree> {
    let config = JSON.parse(  localStorage.getItem(environment.config) );
    console.log( "DataStoreGuard: ", config);
    

    if ( config && config.detSettings.confdeliver === false ) {
      console.log("Configuremos typeSale");
      const tree: UrlTree = this.router.parseUrl("/type-sale-conf");
      return tree;

    } else {
      //console.log("gaaaa: ", type);
      return true;
    }
  }
}
