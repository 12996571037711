import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterpageService {
  headerSale:any;
  headerAndCreditNote:any;
  idProductToEdit

  constructor() { }



  setHeaderSaleData(headerSale:any){
    return new Observable<any>((obs) => { 

      this.headerSale = headerSale;
      obs.next( this.headerSale );

    })

    
   
  }

  getHeaderSaleData(  ){
    return this.headerSale
  }

  //----------------------------------------//
  setheaderAndCreditNote( data ){
    return new Observable<any>((obs) => { 

      this.headerAndCreditNote = data;
      obs.next( this.headerAndCreditNote );

    })
  }


  dataToRegisterProd
  setIdProductToEdit( data ){
    return new Observable<any>((obs) => { 

      this.dataToRegisterProd = data;
      obs.next( this.dataToRegisterProd);

    })
  }

  getIdProductToEdit(){

    return this.dataToRegisterProd

  }



  
  getheaderAndCreditNote(  ){
    console.log("LLevatelo!!!: ", this.headerAndCreditNote  );

    return this.headerAndCreditNote ;
    

  }




  dataToSale:any;
  setDataToSale(data){
    return new Observable<any>((obs) => { 
      console.log("setDataToSale-interpage: ", data );
      

      this.dataToSale = data;
      obs.next( this.dataToSale);

    })
  }

  getDataToSale(){

    return this.dataToSale

  }


  email;
  setEmailToLogin(data){
    return new Observable<any>((obs) => { 
      console.log("Set Email To Login: ", data );
      

      this.email = data;
      obs.next( this.email);

    })
  }

  
  getMailToLogin(){

    return this.email

  }



  dataToproducts:any;
  setDataToProducts(data){
    return new Observable<any>((obs) => { 
      console.log("setDataToSale-interpage: ", data );
      
      //this.idHeaderSale = data.idHeaderSale; //getIdHeaderSale()
      this.dataToproducts = data;
      obs.next( this.dataToproducts);

    })
  }

  getDataToProducts(){

    return this.dataToproducts;

  }


  
  dataToSellModal:any;
  setDataToSellModal(data){

    return new Observable<any>((obs) => { 
      console.log("setDataToSale-interpage: ", data );
      this.dataToSellModal = data;
      this.idHeaderSale = data.idHeaderSale; //getIdHeaderSale()
      obs.next( this.dataToSellModal);
    })
    
  }

  getDataToSellModal(){

    return this.dataToSellModal;

  }




  idHeaderSale:any = '';
  setIdHeaderSale(data){
    return new Observable<any>((obs) => { 
      this.idHeaderSale = data.idHeaderSale;
      obs.next( this.idHeaderSale);
    })
  }

  getIdHeaderSale(){

    return this.idHeaderSale;

  }




  objectiveData:any;
  setObjectiveData(data){
    return new Observable<any>((obs) => { 
      console.log("setObjectiveData: ", data );
      this.objectiveData = data;
      obs.next( this.objectiveData);
    })
  }

  getObjectiveData(){
    return this.objectiveData;
  }  

  myExpencesData:any;
  setMyExpencesData(data){
    return new Observable<any>((obs) => { 
      console.log("setmyExpencesData: ", data );
      this.myExpencesData = data;
      obs.next( this.myExpencesData);
    })
  }

  getMyExpencesData(){
    return this.myExpencesData;
  }
  //--------------------------------------------------//
  dataToNewClient:any;
  setDataToNewClient(data){
    return new Observable<any>((obs) => { 
      console.log("setdataToNewClient: ", data );
      this.dataToNewClient = data;
      obs.next( this.dataToNewClient);
    })
  }

  getDataToNewClient(){
    return this.dataToNewClient;
  }


}

