
import { InterpageService } from "../services/interpage.service";
import { Injectable } from "@angular/core";
import { Resolve , ActivatedRouteSnapshot } from "@angular/router";

@Injectable({
    providedIn:"root"
})
export class ProductsResolver implements Resolve<any>{
    constructor(
        public interPageService : InterpageService
    ){}

    resolve( route:ActivatedRouteSnapshot ){

            console.log("Mail to loggin ", this.interPageService.getIdHeaderSale() );
            


        return this.interPageService.getDataToProducts();

    }
}