import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotLoginGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }
  canActivate(): boolean {
    let obj: any = this.authService.getToken.pipe(map(data => {
      console.log('holaaaa', data)
      if (!data) {
        return true
      } else {
        //this.router.navigate(["main-page"])
        return false
      }
    }))
    return obj
  }
}
