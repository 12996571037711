import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clientsFilter'
})
export class ClientsFilterPipe implements PipeTransform {

  transform(arreglo: any[] , text: any): any {

/*     console.log("filtroClienteArr: ", arreglo );
    console.log("filtroClienteText: ", text );
 */    

    if(text === ''){
      return arreglo;
    }

    text = text.toLowerCase();

    return arreglo.filter( item =>{
      //let name = `${item.customer.C_NAMCUS  item.customer.C_LASNAMCUS}`

      return item.name.toLowerCase().includes( text );
    })
  }

}
