import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  MenuController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { FcmService } from './services/fcm.service';
import { MenutoggleService } from './services/menutoggle.service';
import { Menu_titles } from './uiModel/menu.const';
import { ROLES_ENUM } from './uiModel/roles.enum';
import { IMenu } from './uiModel/ui-menu.model';
import { RESOURCE_BY_ROLES } from './uiModel/internalRoutes';

import { InterpageService } from './services/interpage.service';
//(window).pdfWorkerSrc = './scripts/pdf.worker.js';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  datocodificado: any;
  datoscaneado: {};
  currentRoute = '';
  menu: IMenu[];
  user;
  RESOURCE_BY_ROLES = RESOURCE_BY_ROLES;
  idCompany: string = '';
  constructor(
    private platform: Platform,

    private router: Router,
    public http: HttpClient,
    public menuCtrl: MenuController,
    private nav: NavController,
    //  private storage: Storage,
    private authService: AuthService,
    //private fcm: FCM,
    public plt: Platform,
    private menutoggleService: MenutoggleService,
    public toastController: ToastController,
    public fcm: FcmService,
    private interPageservice:InterpageService
  ) {
    this.initializeApp();
    //(window as any).pdfWorkerSrc = '/assets/pdfjs/pdf.worker.min.js';
    
  }

  ngOnInit() {
    this.currentRoute = this.router.url;
    //this.menuCtrl.enable(true);
    this.menu = Menu_titles;

    /* console.log('el menucito: ', this.menu);
    this.idCompany = localStorage.getItem(environment.idcompany);
    console.log('idCompany for menu: ', this.idCompany);
    if (this.idCompany != 'null') { 
      this.menuCtrl.enable(true);
    } else {
      this.menuCtrl.enable(false);
    } */
  }

  initializeApp() {
    this.platform.ready().then(() => {3
      console.log("__APP_COMPONENT__");
      
      
      SplashScreen.hide();
      this.fcm.initPush();
     // this.getDeviceInfo();
      this.seePresentation();
      
      
    });
  }

  seePresentation(){
    let seePresentation = localStorage.getItem(environment.presentation);
    localStorage.setItem("rapidSale","0");
    console.log("see presentation: ", seePresentation );
    
    if( seePresentation == 'false' ){
    this.router.navigate(['enter-options'])
    //this.router.navigate(['configurations'])
    //this.router.navigate(['reports/commodity'])
      
    }else{
        this.router.navigate(['presentation'])


        
    }
  }



  async presentToast(msm: string) {
    const toast = await this.toastController.create({
      message: msm,
      position: 'top',
      duration: 4000,
      color: 'success',
    });
    toast.present();
  }

  porCerrar(close: any) {
    this.menutoggleService.setMenu(close);
  }
  porAbrir(open: any) {
    this.menutoggleService.setMenu(open);
  }


  async cerrarSesion() {
    //  this.storage.set("URL_APP_TMP", null);

    localStorage.removeItem(environment.idcompany);
    localStorage.removeItem(environment.paymethod);
    localStorage.removeItem(environment.paymethods);
    localStorage.removeItem(environment.iduser);
    localStorage.removeItem(environment.rol);
    localStorage.removeItem(environment.nameuser);
    localStorage.removeItem(environment.numberprods);
    localStorage.removeItem(environment.iddefaultcustomer);

    localStorage.removeItem(environment.uponpayment);
    localStorage.removeItem(environment.typeSale);
    localStorage.removeItem(environment.prepayment);
    localStorage.removeItem(environment.namcom);
    localStorage.removeItem(environment.deliverymount);
    localStorage.removeItem(environment.config);
    localStorage.removeItem(environment.device_v);
    localStorage.removeItem(environment.platform);

    //await this.dbservice.destroyAllTables();
    //this.trackingService.stopBackGroundService();
    //this.menuCtrl.enable(false);
    this.nav.navigateRoot(['login']);
  }

  getUserName(): string {
    let nameuser = localStorage.getItem(environment.nameuser);
    if (nameuser) {
      return nameuser;
    }

    return '';
  }

  getNumberProds(): string {
    let counterprods = localStorage.getItem(environment.numberprods);

    if (counterprods) {
      let number = counterprods;
      return counterprods.toString();
    }

    return '';
  }

  getNamcom(): string {
    let namcom = localStorage.getItem(environment.namcom);

    //console.log("NAMCOmm: " , namcom  );
    

    if (namcom != 'null' ) {
     // this.menuCtrl.enable(true)
      return namcom.toString();
    }

    //this.menuCtrl.enable(false);
    return '';
  }

  redirectTo(page: string) {
    setTimeout(() => {
     
      

      if(page=='/products'  )  {
        let data = {
          tab:'ITEMS',
          reload:true
        }
    
        this.interPageservice.setDataToProducts(data)
    
        .subscribe( (rpta)=>{
          console.log("enviando data: ", rpta);
          
          this.router.navigate(['products'] );
        } )

   

      }else if(page == 'close'){
        this.cerrarSesion();
      }else{
        this.router.navigate([page]);
      }

       
   


      this.menuCtrl.close();


    }, 200);
  }

  clientRedirect() {
    this.router.navigate(['clients']);
    this.menuCtrl.close();
  }

  homeRedirect() {
    this.router.navigate(['main-page']);
    this.menuCtrl.close();
  }
  profileRedirect() {
    this.router.navigate(['profile']);
    this.menuCtrl.close();
  }

  perfilRedirect() {
    console.log('perfil del cliente');

    this.router.navigate(['client']);
    this.menuCtrl.close();
  }

  redirectToSell() {
    this.router.navigate(['sell']);
    this.menuCtrl.close();
  }

  redirectToUSERREGISTER() {
    this.router.navigate(['user-register']);
    //this.menuCtrl.close();
  }

  closeMenu() {
    //this.menuCtrl.close();
  }

  hasPermission(roles: ROLES_ENUM[]): boolean {
    if (roles) {
      return this.authService.hassAccessToModule(roles);
    }

    return true; //si seteo el permiso mostrarlo en el menú.
  }



/*   ionViewDidEnter(): void {
    this.menuCtrl.enable(false)
  }
  
  ionViewDidLeave(): void {
    this.menuCtrl.enable(true);
  }
   */



}
