import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatabaseService } from 'src/app/services/database.service';
import { environment } from 'src/environments/environment';
import { patternName } from '../../patterns/patterns.const';

@Component({
  selector: 'app-new-category',
  templateUrl: './new-category.component.html',
  styleUrls: ['./new-category.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewCategoryComponent implements OnInit, OnDestroy {
  public categoryForm: FormGroup;
  categoryFormInitValue;
  currentPosition;
  height;
  minimumThreshold;
  startThreshold;
  startPosition;
  reloadBack = false;
  isWait = false;
  patternName = patternName;
  subscription: Subscription;
  clickToErrorHttpSubs: Subscription;
  setCategorySubs: Subscription;
  unsubscribeSignal: Subject<void> = new Subject();

  constructor(
    private _fb: FormBuilder,
    private _dataService: DatabaseService,
    public toastController: ToastController,
    public renderer: Renderer2
  ) {
    console.log('new Cat 1');
  }

  public errorMsm = {
    C_DESCAT: [
      { type: 'required', message: 'El nombre es requerido.' },
      { type: 'pattern', message: 'Caractéres no permitidos.' },
      { type: 'maxlength', message: 'Máximo 20 caracteres porfavor.' },
    ],
  };

  get C_DESCAT() {
    return this.categoryForm.get('C_DESCAT');
  }

  ngOnInit() {
    console.log('new cat 2');
    this.clickToErrorHttpSubs = this._dataService
      .getClickToErrorHttp()
      .subscribe(() => {
        console.log('main page error!!!');
        this.isWait = false;

        //this.loaderService.hide();
      });

    this.categoryForm = this._fb.group({
      C_DESCAT: [
        '',
        [
          Validators.required,
          Validators.maxLength(20),
          Validators.pattern(this.patternName),
        ],
      ],
      IDCOMPANY: [
        localStorage.getItem(environment.idcompany),
        Validators.required,
      ],
      C_STATUS: ['1', [Validators.required]],
    });

    this.categoryFormInitValue = this.categoryForm.value;

    /* this.eventsSubscription = this.events.subscribe(() =>{
    console.log("CHILD CHILD");
    this.open('');
  }); */

    this.close();
    this.subscription = this._dataService.getCategoryEvent()
    .pipe( 
      takeUntil( this.unsubscribeSignal.asObservable() )
     ).subscribe(() => {
      console.log('clickEvent open new CAT');

      this.open('');
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.clickToErrorHttpSubs.unsubscribe();
    this.unsubscribeSignal.next();
    this.unsubscribeSignal.complete();
  }

  setNewCategory() {
    for (let u in this.categoryForm.controls) {
      this.categoryForm.controls[u].markAsTouched();
    }

    if (this.categoryForm.valid) {
      let request = {
        ...this.categoryForm.value,
      };

      this.isWait = true;
      this.setCategorySubs = this._dataService
        .setCategory_getData(request)
        .pipe( 
          takeUntil( this.unsubscribeSignal.asObservable() )
         )
        .subscribe((categories: any) => {
          console.log('nueva categoria?: ', categories);
          this.isWait = false;
          if (categories.status == 'true') {
            this._dataService.sendReloadCategory();
            this.close();
            this.categoryForm.reset(this.categoryFormInitValue);

            this.presentToast('Se añadio la nueva categoría.');
          } else {
            console.log('ocirrio un error al guarda Categoria');
          }
        } , err=>this.isWait = false );
    }
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'middle',
      duration: 2000,
      color: 'warning',
    });
    toast.present();
  }

  private eventsSubscription: Subscription;

  @Input() events: Observable<void>;

  //----------------------------------------------------------------------------------//
  @ViewChild('bgCat', { read: ElementRef }) bgCat: ElementRef;
  @ViewChild('bottomSheetCat', { read: ElementRef }) bottomSheetCat: ElementRef;
  open(e) {
    console.log('ee: ', e);

    this.renderer.setStyle(
      this.bottomSheetCat.nativeElement,
      '-webkit-transform',
      'translate3d(0px,0px,0px)'
    );
    this.renderer.setStyle(
      this.bottomSheetCat.nativeElement,
      'transform',
      'translate3d(0px,0%,0px)'
    );
    this.renderer.setStyle(
      this.bottomSheetCat.nativeElement,
      '-webkit-transition-duration',
      '0.3s'
    );
    this.renderer.setStyle(
      this.bottomSheetCat.nativeElement,
      'transition-duration',
      '0.3s'
    );
    this.renderer.setStyle(this.bgCat.nativeElement, 'display', 'block');
  }

  close() {
    this._dataService.sendClickEvent();

    this.categoryForm.reset(this.categoryFormInitValue);
    this.currentPosition = 0;
    this.startPosition = 0;

    (<HTMLStyleElement>(
      document.querySelector('.bottomSheetCat')
    )).style.transform = 'translate3d(0px,100%,0px)';
    (<HTMLStyleElement>document.querySelector('.bgCat')).style.display = 'none';
  }

  touchMoveCategory(evt: TouchEvent) {
    console.log('touchMoveee');

    if (this.startPosition == 0) {
      this.startPosition = evt.touches[0].clientY;
    }

    this.height = document.querySelector('.bottomSheetCat').clientHeight;

    let y = evt.touches[0].clientY;

    this.currentPosition = y - this.startPosition;

    if (this.currentPosition > 0 && this.startPosition > 0) {
      (<HTMLStyleElement>(
        document.querySelector('.bottomSheetCat')
      )).style.transform = `translate3d(0px,${this.currentPosition}px,0px)`;
    }
  }

  touchEnd() {
    this.minimumThreshold = this.height - 130;
    if (this.height) {
      if (this.currentPosition < this.minimumThreshold) {
        (<HTMLStyleElement>(
          document.querySelector('.bottomSheetCat')
        )).style.transform = `translate3d(0px,0px,0px)`;
      } else {
        this.close();
      }
    }
  }
}
