import { Pipe, PipeTransform } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { filter } from 'rxjs/operators';

@Pipe({
  name: 'client'
})
export class ClientPipe implements PipeTransform {

  constructor( private toastController:ToastController ){

  }

  transform( items: any, filterx: any  ): any {
    //return null;

    console.log("Pipe Clients: ", items );
    

    if( !filterx || !Array.isArray( items ) ){
      return items;
    }

    if( filterx && Array.isArray(items) ){

      let filterKeyss = Object.keys( filterx );
      if( false ){

      }else{

        let row = items.filter( (item)=>{
          //console.log("roe itemclient: ", item );;

          return filterKeyss.some( ( keyname )=>{

            //console.log("ITEMM: ", item["customer"] );

            return new RegExp( filterx[keyname] , "gi"  ).test(
              item["customer"][keyname]
            );

          });

        } );

       console.log("RAW is WAR: ", row );
    

       if( row.length == 0 ){
   
         this.toastController.getTop().then( ( top )=>{
           if( !top ){
             this.presentToast("Sin resultados, cargue mas en el boton inferior.");
           }
           
     
         } )
          
           
         }
   
        
        return row;


      }

    }



    return items




  }


  async presentToast(msm: string) {
    const toast = await this.toastController.create({
      message: msm,
      position: 'middle',
      duration: 2000,
      color: 'success',
    });
    toast.present();


  

  }

}
