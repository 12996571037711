import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';

@Pipe({
  name: 'payMethodsPipe'
})
export class PayMethodsPipe implements PipeTransform {

  transform( methods :any ,  filterx: any  ): any {

    if( !filterx || !Array.isArray(methods) ){
      return methods;
    }

    if( filterx && Array.isArray(methods)  ){

      let filterKeyss = Object.keys( filterx );
      if (false) {
      } else {

        let row = methods.filter( (item)=>{

          return filterKeyss.some( ( keyname )=>{

            return new RegExp( filterx[keyname], 'gi' ).test(
              item[keyname]
            )


          } )
          


        } )

        return row

      }

    }


  }

}
