import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { PopoverController, ToastController } from "@ionic/angular";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DatabaseService } from "src/app/services/database.service";
import { InterpageService } from "src/app/services/interpage.service";

@Component({
  selector: "app-pop-info",
  templateUrl: "./pop-info.component.html",
  styleUrls: ["./pop-info.component.scss"],
})
export class PopInfoComponent implements OnInit , OnDestroy{
  @Input("product") product;
  items = [];
  interPageSubs: Subscription;
  updSaleSubs: Subscription;
  unsubscribeSignal: Subject<void> = new Subject();
  constructor(
    private popoverController: PopoverController,
    private _dataService: DatabaseService,
    private router: Router,
    private toastCtrl: ToastController,
    private interPageService: InterpageService
  ) {}

  ngOnInit() {
    console.log("Se paso esta lista a popoinfo: ", this.product);

    //this.getReminder(this.idProduct);
  }

  ngOnDestroy(){
    this.unsubscribeSignal.next();
    this.unsubscribeSignal.complete();

  }

  seleccionar(a: any) {
    console.log("Id de stock del producto con varias tallas: ", a);

    if (a != "all") {
      this.popoverController.dismiss({
        selecc: [a],
      });
    } else {
      console.log("no se manda nada");
      this.popoverController.dismiss();
    }
  }

  getReminder(id) {
    console.log("procede la busqueda(sell-product)!: ", id);

    /* this._dataService.getProductWithStock(id).subscribe(
      (rpta: any) => {
        console.log("Rpta: ", rpta);

        if (rpta.status === "true") {
          //si tiene talla poner vista con el check activado;
          if (rpta.data.product[0].isSizing === "0") {
          } else if (rpta.data.product[0].isSizing === "1") {
            //el check activo
            //llenemos ambos formularios:

            this.items = rpta.data.stocks;

          } else {
            console.log("no tiene seteaso el isSing correctamente.");
          }
        } else {
          console.log("error en la petiocn de stock-product");
          //
        }
      },
      (err) => {
        console.log("error: ", err);
      }
    ); */
  }

  editSale() {

    let dataToSale = {
      id_product_to_edit: this.product.IDPRODUCT,
      idHeaderSale:this.product.N_IDHEASAL,
      idSale: this.product.IDSALE,
    }

    this.interPageSubs = this.interPageService.setDataToSale( dataToSale )
    .pipe( 
      takeUntil( this.unsubscribeSignal.asObservable() )
      )
    .subscribe( (rpta)=>{

      console.log("dataToSale(popover): ", rpta );
      

          this.router.navigate(["sell-product"]);
    this.popoverController.dismiss();
    })


  }

  deleteSale() {
    console.log("vamos a actualizar: ", this.product.IDSALE);
    if (this.product.IDSALE) {
      let request = {
        IDSALE: this.product.IDSALE,
        N_QUANTI: "xxx",
        N_SUBTOT: "xxx",
        C_STATUS: "0",
      };

      console.log("request, edtSale: ", request);

      this.updSaleSubs = this._dataService.updSale(request).subscribe((rpta: any) => {
        console.log("actualizacion?: ", rpta);

        this.presentToast("Se actualizo con éxito");
        this.popoverController.dismiss({
          N_IDHEASAL:this.product.N_IDHEASAL
        });
      });
    }
  }

  async presentToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      position: "top",
    });
    toast.present();
  }
}
